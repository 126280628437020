// user-modal.component.ts
import { Component } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { SPKRestService } from '../spk-cash-rest.service';
import { CommonService } from '../../../libraries/common/common.service';
import Swal from 'sweetalert2';
import { SPKListComponent } from '../list/spk-list.component';

@Component({
  selector: 'app-create-modal',
  templateUrl: 'create-modal.component.html'
})
export class CreateModalComponent {
  formModalCreate: FormGroup;
  invoiceImageBase64: any;
  selectedFileName: string;
  fileSize: any;
  showModalCreate: boolean = false;
  loadingCreate: boolean = false;
  unitItems: any[] = [];
  dealerItems: any[] = [];
  batteryTypes: any[] = [];

  constructor(
    private _SPKRest: SPKRestService,
    public spkListComponent: SPKListComponent
  ) {}

  ngOnInit(): void {
    this.initFormSPK();
  }

  async getUnits() {
    await this._SPKRest.getUnitType().subscribe((data: any) => {
      this.unitItems = data?.map(item => ({
        ...item,
        name: `${item?.productTypeId?.name} - ${item?.color}`
      }));
    });
  }

  getDealers() {
    this._SPKRest.getDealers().subscribe((data: any) => {
      this.dealerItems = data;
    });
  }

  getBatteries() {
    this._SPKRest.getBatteries().subscribe((data: any) => {
      this.batteryTypes = data.filter((item: any) => item.stock > 0);
    });
  }

  onChangeRegion(e) {
    const dealerId = e.target.value;
    this.formModalCreate.patchValue({ dealerId });
  }

  capitalizeFirstLetter(str: string): string {
    if (str === 'sewa') {
      return 'Sewa (Motor Tanpa Baterai)';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  addFile(event) {
    if (event.target.files.length > 0) {
      // const sizeLimit = 1048576;
      // if (event.target.files[0].size > sizeLimit) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Error',
      //     text: this.comp._translate.instant('error.image.maxLimitProduct')
      //   });
      //   this.resetFileImg();
      //   return false;
      // }
      const isPdf = event.target.files[0]?.type === 'application/pdf';
      const reader = new FileReader();
      const file = event.target.files[0];
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);

      this.selectedFileName = file.name;
      this.fileSize = fileSizeMB + 'MB';

      this.formModalCreate.patchValue({
        invoice: { file }
      });

      if (isPdf) {
        this.invoiceImageBase64 = 'pdf';
        return;
      }

      reader.onload = () => {
        this.invoiceImageBase64 = reader.result as string;
      };

      reader.readAsDataURL(file);
    }
  }

  clearFile(event: Event) {
    event.preventDefault();
    this.invoiceImageBase64 = null;
    this.selectedFileName = '';
    this.fileSize = null;
    this.formModalCreate.patchValue({
      invoice: { file: null }
    });
  }

  resetFileImg() {
    this.invoiceImageBase64 = null;
    this.selectedFileName = '';
    this.fileSize = null;
  }

  async createSPK() {
    const values = this.formModalCreate.value;
    const payload = new FormData();

    Object.keys(values).forEach(key => {
      if (key === 'invoice') {
        payload.append('invNumber', values[key]?.invNumber);
        payload.append('invFile', values[key]?.file);
      } else if (key === 'units') {
        payload.append(key, JSON.stringify(values[key]));
      } else {
        payload.append(key, values[key]);
      }
    });

    if (!this.formModalCreate.valid) {
      CommonService.markAsDirty(this.formModalCreate);
    } else {
      this.loadingCreate = true;
      await this._SPKRest
        .createSPK(payload)
        .toPromise()
        .then((response: any) => {
          if (!!response?.invalid) {
            Swal.fire({
              icon: 'warning',
              title: 'Invalid Unit Stock',
              html: response?.invalidStockVariant
                ?.map(
                  item => `
            <div class="text-left">
                <div>Unit: ${item.unit}</div>
                <div>Total: ${item.total}</div>
                <div>Current Stock: ${item.currentStock}</div>
                <hr/>
            </div>
            `
                )
                .join(''),
              confirmButtonText: 'OK'
            });
          } else {
            if (response) {
              Swal.fire('Create SPK successfully!', '', 'success');

              this.spkListComponent.callHookDirectly('init');
              this.openCreateSPKModal(false);
            }
          }
        })
        .catch(error => {
          const errorData = { ...error };
          Swal.fire('Gagal', errorData?.response?.data?.message, 'error');
        });
      this.loadingCreate = false;
    }
  }

  initFormSPK() {
    this.formModalCreate = new FormGroup({
      dealerId: new FormControl('', [Validators.required]),
      shippingAddress: new FormControl('', [Validators.required]),
      invoice: new FormGroup(
        {
          invNumber: new FormControl(''),
          file: new FormControl('')
        },
        { validators: this.invoiceValidation }
      ),
      units: new FormArray([this.createUnitFormGroup()])
    });
  }

  stockValidator(initialStock: number) {
    return (control: FormControl): { [key: string]: any } | null => {
      const currentStock = control.value;
      if (currentStock > initialStock) {
        return { invalidStock: true };
      }
      return null;
    };
  }

  onUnitSelected(unit, index) {
    const productTypeVariantId = unit?.value?.productTypeVariantId;
    const selectedUnit = this.unitItems?.find(
      item => item._id == productTypeVariantId
    );
    const selectedUnitStock = selectedUnit?.stock;
    const selectedUnitName = selectedUnit?.name;

    const unitFormGroup = this.formModalCreate
      ?.get('units')
      ?.get(index.toString()) as FormGroup;
    const totalFormControl = unitFormGroup.get('total');

    // Set unit name
    unitFormGroup.get('unit')?.setValue(selectedUnitName);

    // Update the initialStock property in the form control
    totalFormControl?.setValidators([
      Validators.required,
      this.stockValidator(selectedUnitStock)
    ]);
    totalFormControl?.updateValueAndValidity();

    console.log(this.formModalCreate.value);
  }

  createUnitFormGroup(): FormGroup {
    return new FormGroup({
      unit: new FormControl('', [Validators.required]),
      total: new FormControl('', [Validators.required, this.stockValidator(0)]),
      description: new FormControl(''),
      batteryType: new FormControl(''),
      productTypeVariantId: new FormControl('', [Validators.required])
    });
  }

  addUnit() {
    const units = this.formModalCreate.get('units') as FormArray;
    units.push(this.createUnitFormGroup());
  }

  resetUnits() {
    const newUnitsArray = new FormArray([this.createUnitFormGroup()]);
    this.formModalCreate.setControl('units', newUnitsArray);
  }

  removeUnit(index: number) {
    const units = this.formModalCreate.get('units') as FormArray;
    units.removeAt(index);
  }

  get unitsControls() {
    return (this.formModalCreate.get('units') as FormGroup).controls;
  }

  invoiceValidation(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const invNumberControl = control.get('invNumber')?.value;
    const fileControl = control.get('file')?.value;

    if (
      (fileControl && !invNumberControl) ||
      (!fileControl && invNumberControl)
    ) {
      return { requiredField: true };
    }

    return null;
  }

  openCreateSPKModal(val) {
    this.getUnits();
    this.getDealers();
    this.getBatteries();
    this.showModalCreate = val;
    this.formModalCreate.reset();
    this.resetUnits();
    this.resetFileImg();
  }
}
