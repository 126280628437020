import { Component, ElementRef, Input } from '@angular/core';
import 'moment/locale/id';
import { RequestFakturRestService } from '../request-faktur-rest.service';
import Swal from 'sweetalert2';
import { APP_CONST } from '../../../app.const';
import { RequestFakturListComponent } from '../list/request-faktur-list.component';

@Component({
  selector: 'app-shipping-modal',
  templateUrl: 'shipping-modal.component.html',
  styleUrls: ['../request-faktur-module.scss']
})
export class ShippingModalComponent {
  @Input() requestFaktur: any;
  @Input() disabled: boolean;
  isModalOpen: boolean = false;
  uploadEndpoint = APP_CONST.API_FILE;

  formShipping = {
    noResi: '',
    noFaktur: '',
    fotoBuktiPengiriman: '',
  }

  constructor(
    private _RequestFakturRest: RequestFakturRestService,

    public _requestFakturListComponent: RequestFakturListComponent
  ) {}

  resetFormData() {
    this.formShipping = {
      noResi: '',
      noFaktur: '',
      fotoBuktiPengiriman: '',
    };
  }

  openModal() {
    this.isModalOpen = true;
    this.resetFormData();
  }
  
  closeModal() {
    this.isModalOpen = false;
    this.resetFormData();
  }

 
  onImageFinishUploaded(image: any, inputName: string) {
    if (image?.storageFileName) {
      (this.formShipping as any)[inputName] = image.storageFileName
    } else {
      Swal.fire("Error", "Image upload failed or missing storageFileName.", 'error');
    }
  }

  // Method to handle the submit action
  onSubmit(form) {    
    if (form.valid) {
      // Prepare the data to be submitted
      const dataToSubmit = this.formShipping
        
      console.log('Data to submit:', dataToSubmit);
      
      // Here you would call your service to submit the data
      this._RequestFakturRest.shipping(this.requestFaktur._id, dataToSubmit).subscribe({
          next: response => {
            Swal.fire('Success', 'Request faktur shipping update success!', 'success');
            this._requestFakturListComponent.callHookDirectly('init');
            this.closeModal(); // Close modal on success
          },
          error: err => {
            const errorMessage = err.response.data.message;
                      
            Swal.fire('Error', `Failed to update request faktur shipping: ${errorMessage}`, 'error');
          }
        });
    } else {
      Swal.fire("Error", 'Please fill in all required fields.', 'error');
    }
  }
}
