import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SPKRestService } from '../../spk-cash-rest.service';
import { CommonService } from '../../../../libraries/libraries.module';
import Swal from 'sweetalert2';
import { SPKListComponent } from '../../list/spk-list.component';

type UnitType = {
  unit: string;
  total: number;
  productTypeVariantId: string;
  description: string;
  batteryType: string;
};

@Component({
  selector: 'app-form-update-units',
  templateUrl: 'form-update-units.component.html',
  styleUrls: ['../../spk-cash-component.scss']
  // styleUrls: ['status-info-modal.component.scss']
})
export class FormUpdateUnitsComponent {
  @Input() units: UnitType[];
  @Input() spk: any;
  @Output() onClose = new EventEmitter();

  constructor(
    private _SPKRest: SPKRestService,
    public _spkListComponent: SPKListComponent
  ) {}

  batteryTypes: any[] = [];
  formUpdateUnits: FormGroup;
  unitItems: any[] = [];
  loadingUpdateUnits: boolean = false;

  stockValidator(initialStock: number) {
    return (control: FormControl): { [key: string]: any } | null => {
      const currentStock = control.value;
      if (currentStock > initialStock) {
        return { invalidStock: true };
      }
      return null;
    };
  }

  createUnitFormGroup(data?: UnitType): FormGroup {
    return new FormGroup({
      unit: new FormControl(data?.unit || '', [Validators.required]),
      total: new FormControl(data?.total || '', [
        Validators.required,
        this.stockValidator(data?.total || 0)
      ]),
      description: new FormControl(data?.description || ''),
      batteryType: new FormControl(data?.batteryType || ''),
      productTypeVariantId: new FormControl(data?.productTypeVariantId || '', [
        Validators.required
      ])
    });
  }

  onUnitSelected(unit, index) {
    const productTypeVariantId = unit?.value?.productTypeVariantId;
    const selectedUnit = this.unitItems?.find(
      item => item._id == productTypeVariantId
    );
    const selectedUnitStock = selectedUnit?.stock;
    const selectedUnitName = selectedUnit?.name;

    const unitFormGroup = this.formUpdateUnits
      ?.get('units')
      ?.get(index.toString()) as FormGroup;
    const totalFormControl = unitFormGroup.get('total');

    // Set unit name
    unitFormGroup.get('unit')?.setValue(selectedUnitName);

    // Update the initialStock property in the form control
    totalFormControl?.setValidators([
      Validators.required,
      this.stockValidator(selectedUnitStock)
    ]);
    totalFormControl?.updateValueAndValidity();
  }

  initFormUpdateUnits() {
    this.formUpdateUnits = new FormGroup({
      units: new FormArray([
        ...this.units?.map(unit => this.createUnitFormGroup(unit))
      ])
    });
  }

  addUnit() {
    const units = this.formUpdateUnits.get('units') as FormArray;
    units.push(this.createUnitFormGroup());
  }

  removeUnit(index: number) {
    const units = this.formUpdateUnits.get('units') as FormArray;
    units.removeAt(index);
  }

  get unitsControls() {
    return (this.formUpdateUnits.get('units') as FormGroup).controls;
  }

  validSPKStatus() {
    return !['expired', 'rejected'].includes(this.spk?.status);
  }

  async getUnits() {
    await this._SPKRest.getUnitType().subscribe((data: any) => {
      this.unitItems = data?.map(item => {
        const currentUserUnit = this.units?.find(
          currentUnit => currentUnit.productTypeVariantId === item._id
        );

        return {
          ...item,
          name: `${item?.productTypeId?.name} - ${item?.color}`,
          stock:
            currentUserUnit && this.validSPKStatus()
              ? Number(item.stock) + currentUserUnit.total
              : item.stock
        };
      });

      // Insert empty units to form update unit
      const inStockUnits = data?.map(item => item._id);
      const emptyStockUnits = this.units.filter(
        unit => !inStockUnits.includes(unit.productTypeVariantId)
      );
      this.unitItems.push(
        ...emptyStockUnits.map(item => ({
          ...item,
          _id: item.productTypeVariantId,
          name: item.unit,
          stock: item.total
        }))
      );
    });
  }

  getBatteries() {
    this._SPKRest.getBatteries().subscribe((data: any) => {
      this.batteryTypes = data.filter((item: any) => item.stock > 0);
    });
  }

  onCloseUpdateUnits() {
    this.onClose.emit();
  }

  capitalizeFirstLetter(str: string): string {
    if (str === 'sewa') {
      return 'Sewa (Motor Tanpa Baterai)';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  onUpdateUnits() {
    if (!this.formUpdateUnits.valid) {
      CommonService.markAsDirty(this.formUpdateUnits);
    } else {
      console.log(this.formUpdateUnits.value.units);
      Swal.fire({
        title: 'Update Units?',
        text: '',
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.updateUnits();
        }
      });
    }
  }

  async updateUnits() {
    const payload = {
      spkId: this.spk?._id,
      units: this.formUpdateUnits.value.units
    };

    this.loadingUpdateUnits = true;
    this._SPKRest
      .updateUnits(payload)
      .toPromise()
      .then((response: any) => {
        if (!!response?.invalid) {
          Swal.fire({
            icon: 'warning',
            title: 'Invalid Unit Stock',
            html: response?.invalidStockVariant
              ?.map(
                item => `
            <div class="text-left">
                <div>Unit: ${item.unit}</div>
                <div>Total: ${item.total}</div>
                <div>Current Stock: ${item.currentStock}</div>
                <hr/>
            </div>
            `
              )
              .join(''),
            confirmButtonText: 'OK'
          });
        } else {
          this._spkListComponent.callHookDirectly('init');
          Swal.fire('Update units Successfully!', '', 'success');
        }
      })
      .catch(error => {
        const errorData = { ...error };
        Swal.fire('Gagal', errorData?.response?.data?.message, 'error');
      })
      .finally(() => {
        this.loadingUpdateUnits = false;
        this.onCloseUpdateUnits();
      });
  }

  ngOnInit(): void {
    this.initFormUpdateUnits();
    this.getUnits();
    this.getBatteries();
  }
}
