import { Component, ElementRef, NgModule } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { SPKRestService } from '../spk-indent-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { APP_CONST } from '../../../app.const';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../libraries/common/common.service';
import Swal from 'sweetalert2';
import { AclService } from '../../../libraries/auth/acl.service';
import { AuthenticationService } from '../../../libraries/libraries.module';

@Component({
  selector: 'app-spk-indent-list',
  templateUrl: 'spk-list.component.html',
  styleUrls: ['../spk-indent-component.scss']
})
export class SPKListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.spk.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.spk.entity.spkNumber',
      field: 'spkNumber',
      sort: true
    },
    {
      i18nLabel: 'ui.spk.entity.status',
      name: 'status',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.spk.entity.detailPO',
      name: 'detailPO',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.spk.entity.dealer',
      name: 'dealer',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.spk.entity.invoiceNumberCash',
      name: 'invoiceNumber',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.spk.entity.invoiceDP',
      name: 'invoiceDP',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.spk.entity.proofOfPaymentDP',
      name: 'proofOfPaymentDP',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.spk.entity.invoiceSettlement',
      name: 'invoiceSettlement',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.spk.entity.proofOfPaymentSettlement',
      name: 'proofOfPaymentSettlement',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.spk.entity.deliveryDate',
      name: 'deliveryDate',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.spk.entity.createdAt',
      field: 'createdAt',
      sort: true
    },
    {
      i18nLabel: 'ui.spk.entity.updatedAt',
      field: 'updatedAt',
      sort: true
    },
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(
    this.tableColumns
  );
  isLoadingExportExcel: boolean = false;
  isDealerModalOpen: boolean = false;
  dealerOpened: any = {};

  isPOModalOpen: boolean = false;
  poOpened: any = {};

  isInvoiceModalOpen: boolean = false;
  invoiceOpened: any = {};
  loadingCreateInvoice: boolean = false;

  selectedSPK: any = {};

  isEditInvoice: boolean = false;
  selectedInvoiceType: string = ''; // Can be invoice or performaInvoice
  formCreateInvoice: FormGroup;

  isChassisNumberModalOpen: boolean = false;

  previewBase64Image: any;
  selectedFileName: string;
  fileSize: any;

  subsidyDiscountPrice: number = 0;
  isPdfSelected: boolean = false;

  isModalChangeStatusOpen: boolean = false;

  statusList = [
    {
      icon: '../../../../assets/img/icons/new.svg',
      title: 'New',
      description: 'SPK Baru Dibuat'
    },
    {
      icon: '../../../../assets/img/icons/waiting-payment.svg',
      title: 'Waiting Payment',
      description: 'Setelah PI diupload oleh pabrik'
    },
    {
      icon: '../../../../assets/img/icons/po-paid.svg',
      title: 'SPK Paid',
      description: 'Dealer sudah melakukan pembayaran, dan menunggu pabrik mengupdate data no rangka dan no polisi'
    },
    {
      icon: '../../../../assets/img/icons/waiting-sisapira.svg',
      title: 'Waiting Sisapira',
      description: 'Data motor sudah ada, menunggu dealer mendaftarkan ke sisapira dan mengupload bukti verifikasi sisapira'
    },
    {
      icon: '../../../../assets/img/icons/waiting-verification.svg',
      title: 'Waiting Verification',
      description: 'Menunggu admin Volta meng-approve data sisapira dari dealer'
    },
    {
      icon: '../../../../assets/img/icons/approved.svg',
      title: 'Approved',
      description: 'Data sudah di approve admin volta, button claim akan dimunculkan dan dealer bisa melakukan claim subsidi'
    },
    {
      icon: '../../../../assets/img/icons/waiting-claim.svg',
      title: 'Waiting Claim',
      description: 'Dealer sudah mengajukan claim dan tinggal menunggu approval dari admin Volta'
    },
    {
      icon: '../../../../assets/img/icons/claimed.svg',
      title: 'Claimed',
      description: 'Pengajuan claim dari dealer sudah di approve'
    },
    {
      icon: '../../../../assets/img/icons/expired.svg',
      title: 'Expired',
      description: 'SPK telah kedaluwarsa'
    },
    {
      icon: '../../../../assets/img/icons/po-delivered.svg',
      title: 'Delivered',
      description: 'Motor dalam proses pengiriman'
    },
    {
      icon: '../../../../assets/img/icons/approved.svg',
      title: 'Completed',
      description: 'SPK telah selesai'
    },
    {
      icon: '../../../../assets/img/icons/rejected.svg',
      title: 'Rejected',
      description: 'SPK telah ditolak'
    },
  ]

  spkStatuses = [
    {
      text: 'Semua Status SPK',
      value: ''
    },
    {
      "text": "New",
      "value": "new"
    },
    {
      "text": "Waiting Payment",
      "value": "waiting payment"
    },
    {
      "text": "SPK Paid",
      "value": "spk paid"
    },
    {
      "text": "Delivered",
      "value": "delivered"
    },
    {
      "text": "Expired",
      "value": "expired"
    },
    {
      "text": "Rejected",
      "value": "rejected"
    },
  ];

  dealerFilter = [
    {
      name: 'Semua Dealer',
      _id: ''
    },
  ];

  constructor(
    public _authentication: AuthenticationService,
    public _SPKRest: SPKRestService,
    private _export: ExportDataTableService,
    private _AclService: AclService,
    private el: ElementRef
  ) {
    super();
    this.componentId = 'SPKListComponent';
    this.headerTitle = 'ui.spk.list.titleIndent';
    this.initFormInvoice();

    this.qParams.status = '';
    this.qParams.dealerId = '';
  }

  isDealership = this._authentication?.user?.role?.name?.toLowerCase() === 'dealership';

  statusIconPath(status: string) {
    const selected = this.statusList.find(item => item.title.toLowerCase() === status)

    return selected?.icon;
  }

  canAccess(pageAction: string) {
    const userAccess: string[] = this._AclService?.exclusivePermissions;
    return userAccess.find(action => action === pageAction);
  }

  openDealerModal(data) {
    this.isDealerModalOpen = true;
    this.dealerOpened = data;
  }

  closeDealerModal() {
    this.isDealerModalOpen = false;
  }

  openPOModal(data) {
    this.isPOModalOpen = true;
    this.poOpened = data || {};
  }

  closePOModal() {
    this.isPOModalOpen = false;
  }

  paidState(spk) {
    if (spk.status == 'spk paid') {
      if (spk.proofOfPaymentSettlement?.status === 'paid') {
        return '(Pelunasan)'
      } else if (spk.proofOfPaymentDP?.status === 'paid') {
        return '(DP)';
      }
    }
    return '';
  }

  onDeleteSPK(id) {
    Swal.fire({
      title: this.comp._translate.instant('confirm.any.delete.label'),
      text: this.comp._translate.instant('confirm.any.delete.description'),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteSPK(id);
      } else {
        return;
      }
    });
  }

  onRejectSPK(id) {
    Swal.fire({
      title: 'Reject SPK ini?',
      text: '',
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.rejectSPK(id);
      } else {
        return;
      }
    });
  }

  onChangeStatusSPK(data) {
    this.selectedSPK = data;
    this.isModalChangeStatusOpen = true;
  }

  closeChangeStatusModal() {
    this.isModalChangeStatusOpen = false;
    this.selectedSPK = {};
  }

  async deleteSPK(id) {
    await this._SPKRest
      .delete(id)
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire(
            'Delete SPK successfully!',
            '',
            'success'
          )
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async rejectSPK(id) {
    const payload = { spkId: id };
    await this._SPKRest
      .rejectSPK(payload)
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire(
            'SPK rejected successfully!',
            '',
            'success'
          )
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async renderPresignedUrlImage(payload: object, elementId: string) {
    await this._SPKRest.getPresignedUrl(payload).subscribe(data => {
      const invoicePreview = this.el.nativeElement.querySelector(elementId);

      if (invoicePreview) {
        invoicePreview.src = data.url;
        invoicePreview.href = data.url;
      }
    }, error => {
      const invoicePreview = this.el.nativeElement.querySelector(elementId);
      if (invoicePreview) {
        invoicePreview.src = '';
      }
    })
  }

  async openInvoiceModal(data, type, isEditInvoice = false) {
    if (['expired', 'rejected'].includes(data.status) && isEditInvoice) return;

    const invoice = data[type]; // Invoice Type
    const isPdf = invoice?.file?.mimeType === 'application/pdf';
    this.isPdfSelected = isPdf;

    if (!isEditInvoice && invoice) {
      const payload = { storageFileName: invoice?.file?.storageFileName }
      if (isPdf) {
        await this.renderPresignedUrlImage(payload, '#js-invoicepdfpreview');
      } else {
        await this.renderPresignedUrlImage(payload, '#js-invoicepreview');
      }
    } else if (isEditInvoice && invoice) {
      if (isPdf) {
        this.previewBase64Image = 'pdf';
      } else {
        const payload = { storageFileName: invoice?.file?.storageFileName }
        this.previewBase64Image = invoice?.file?.storageFileName;
        await this.renderPresignedUrlImage(payload, '#js-editInvoicePreview');
      }

      this.formCreateInvoice.patchValue({ file: invoice?.file?.storageFileName, invNumber: invoice.invNumber });
    }

    this.selectedSPK = data;
    this.isEditInvoice = isEditInvoice;
    this.selectedInvoiceType = type;
    this.isInvoiceModalOpen = true;
    this.invoiceOpened = invoice;
  }
  closeInvoiceModal() {
    this.isInvoiceModalOpen = false;
    this.formCreateInvoice.reset();
    this.resetFileImg();
  }

  toDate(date) {
    return moment(date).format('DD MMM YYYY HH:mm')
  }

  convertImg(url) {
    const imgUrl = `${APP_CONST.API_COMPANY}${url}`;
    return imgUrl;
  }

  convertToIdr(value) {
    const formatted = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR'
    });
    const formattedValue: string = formatted.format(value);
    const arrayFromValue = formattedValue.split(',');
    value = arrayFromValue.splice(0, 1);
    return value;
  }

  async createInvoice() {
    if (!this.formCreateInvoice.valid) {
      CommonService.markAsDirty(this.formCreateInvoice);
    } else {
      const invoiceType = {
        'invoice': 'createInvoice',
        'invoiceDP': 'createInvoiceDP',
        'invoiceSettlement': 'createInvoiceSettlement',
        'performaInvoice': 'createPerformaInvoice'
      }
      const values = this.formCreateInvoice.value;
      const payload = new FormData();

      payload.append('spkId', this.selectedSPK._id);
      payload.append('invNumber', values.invNumber);
      payload.append('file', values.file);

      this.loadingCreateInvoice = true;
      const selectedInvoiceService = invoiceType[this.selectedInvoiceType]
      await this._SPKRest[selectedInvoiceService](payload)
        .toPromise()
        .then(response => {
          if (response) {
            Swal.fire(
              'Update Invoice successfully!',
              '',
              'success'
            )

            this.onGridChange()
            this.closeInvoiceModal()
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.loadingCreateInvoice = false;
      this.clearFile();
    }
  }

  addFile(event) {
    if (event.target.files.length > 0) {
      // const sizeLimit = 1048576;
      // if (event.target.files[0].size > sizeLimit) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Error',
      //     text: this.comp._translate.instant('error.image.maxLimitProduct')
      //   });
      //   this.resetFileImg();
      //   return false;
      // }
      const reader = new FileReader();
      const file = event.target.files[0];
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
      const isPdf = event.target.files[0]?.type === 'application/pdf';


      if (!isPdf) {
        reader.onload = () => {
          this.previewBase64Image = reader.result as string
        };
        reader.readAsDataURL(file);
      } else {
        this.previewBase64Image = 'pdf';
      }

      this.formCreateInvoice.patchValue({ file: file });

      this.selectedFileName = file.name;
      this.fileSize = fileSizeMB + 'MB';
    }
  }

  clearFile(event?: Event) {
    event?.preventDefault();
    this.previewBase64Image = null;
    this.selectedFileName = '';
    this.fileSize = null;
    this.formCreateInvoice.patchValue({ file: null });
  }

  resetFileImg() {
    this.previewBase64Image = null;
    this.selectedFileName = '';
    this.fileSize = null;
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        qOption['type'] = 'indent';
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign(
          {},
          event.data.qParams,
          qOption,
          this.qParams
        );
        return this._SPKRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMM YYYY HH:mm');
              d.updatedAt = moment(d?.updatedAt).format('DD MMM YYYY HH:mm');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () =>
        this.page.compHookService?.callMultipleHooks([
          'SPKListComponent:workflowLoadData'
        ])
    });
  }

  getSubsidyDiscountPrice() {
    this._SPKRest.getSubsidyDiscountPrice().subscribe(data => {
      this.subsidyDiscountPrice = data?.price || 0;
    })
  }

  getDealers() {
    this._SPKRest.getDealers().subscribe((response: any) => {
      const data = response?.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
      this.dealerFilter = [
        ...this.dealerFilter,
        ...data,
      ];
    })
  }

  appOnInit() {
    super.appOnInit();
    this.getSubsidyDiscountPrice();
    this.getDealers();

    return this.callHook('init');
  }

  initFormInvoice() {
    this.formCreateInvoice = new FormGroup({
      invNumber: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
    })
  }

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._SPKRest.findAll({ type: 'indent', ...this.qParams }).toPromise();
      const mapOption = {
        spkNumber: { header: 'Nomor SPK' },
        status: { header: 'Status' },
        shippingAddress: { header: 'Alamat Pengiriman' },
        dealer: { header: 'Dealer' },
        invoice: { header: 'Nomor PO MD' },
        invoiceDP: { header: 'Nomor PI DP' },
        proofOfPaymentDP: { header: 'Bukti Pembayaran PI DP' },
        invoiceSettlement: { header: 'Nomor PI Pelunasan' },
        proofOfPaymentSettlement: { header: 'Bukti Pembayaran PI Pelunasan' },
        deliveryDate: { header: 'Tanggal Rencana Kirim' },
        unit: { header: 'Unit' },
        batteryType: { header: 'Type Baterai' },
        total: { header: 'Total' },
        createdAt: { header: 'Tanggal Dibuat' },
        updatedAt: { header: 'Tanggal Diupdate' },
      };
      const payload = result.data.map(item => ({
        spkNumber: item.spkNumber,
        status: item.status,
        dealer: item.dealer?.name || '-',
        proofOfPaymentDP: item.proofOfPaymentDP?.status || '-',
        proofOfPaymentSettlement: item.proofOfPaymentSettlement?.status || '-',
        invoice: item.invoice?.invNumber || '-',
        invoiceDP: item.invoiceDP?.invNumber,
        invoiceSettlement: item.invoiceSettlement?.invNumber,
        createdAt: this.toDate(item.createdAt),
        updatedAt: this.toDate(item.updatedAt),
        shippingAddress: item.shippingAddress,
        deliveryDate: item.deliveryDate ? moment(item.deliveryDate).format('DD MMM YYYY') : '-',
        units: item.units
      }));

      const filteredPayload: any = [];
      payload.forEach(item => {
        item.units.forEach(unit => {
          delete item.units;
          filteredPayload.push({
            ...item,
            unit: unit.unit,
            total: unit.total,
            batteryType: unit.batteryType,
          })
        })
      })

      this._export.export({
        extension: 'xlsx',
        fileName: `spk-indent-${moment().format('DD-MMM-YYYY-HH:mm')}`,
        records: filteredPayload,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }
}
