import { Component } from '@angular/core';

@Component({
  selector: 'app-status-info-modal',
  templateUrl: 'status-info-modal.component.html',
  styleUrls: ['../list/battery-rent-list.component.scss']
})
export class StatusInfoModal {
  statusList = [
    {
      "title": "Waiting for Pairing",
      "description": "Pelanggan belum melakukan pairing baterai"
    },
    {
      "title": "Waiting for Payment",
      "description": "Pelanggan sudah melakukan pairing baterai tetapi belum melakukan pembelian paket rental baterai"
    },
    {
      "title": "Renting",
      "description": "Pelanggan memiliki paket rental baterai yang aktif"
    },
    {
      "title": "Grace Period",
      "description": "Paket rental yang dimiliki oleh pelanggan sedang dalam masa tenggang."
    },
    {
      "title": "Expired",
      "description": "Paket rental yang dimiliki oleh pelanggan sudah melewati masa tenggang dan baterai dimatikan"
    },
    {
      "title": "Stop Rent",
      "description": "Sewa baterai dihentikan"
    },
    {
      "title": "Replacement",
      "description": "Baterai dilakukan penggantian"
    }
  ];  

  isOpen: boolean = false;

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }
}
