import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SPKBatteryRentRestService } from '../spk-battery-rent-rest.service';
import Swal from 'sweetalert2';
import { SPKBatteryRentListComponent } from '../list/spk-battery-rent-list.component';
import { AuthenticationService, CommonService } from '../../../libraries/libraries.module';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { APP_CONST } from '../../../app.const';

@Component({
  selector: 'app-submission-modal',
  templateUrl: 'submission-modal.component.html',
  styleUrls: ['../list/spk-battery-rent-list.component.scss']
})
export class SubmissionModalComponent {
  constructor(
    private _SPKBatteryRentRest: SPKBatteryRentRestService,
    public _spkBatteryRentListComponent: SPKBatteryRentListComponent,
    public _authentication: AuthenticationService,
    private fb: FormBuilder
  ) {
    // this.initFormSubmission();
  }

  isLoading: boolean = false;
  isNewSubmissionModalOpen: boolean = false;
  isBackupSubmissionModalOpen: boolean = false;
  isReplacementSubmissionModalOpen: boolean = false;
  replacementSubmissionOpened: any = {};
  spkNumber = new FormControl('');
  batteryCode = new FormControl('');
  spkNumberSubmission: string = '';
  motorcycleUnits: any[] = [];
  totalAllUnits: number = -1;
  type: string = ''; // type for battery rent submission / tipe untuk pengajuan baterai sewa
  loadingUpdateProfileDealer: boolean = false;
  dealer: any = {};
  isDisabled = true; // Initial state of the textarea
  batteries: any[] = [
    {
      voltageType: '',
      total: 1,
      description: '',
      spkNumber: ''
    }
  ];
  uploadEndpoint = APP_CONST.API_FILE;

  toggleDisable(): void {
    this.isDisabled = !this.isDisabled;
  }

  // Create a form group for each battery
  createBatteryFormGroup(battery: any): FormGroup {
    return this.fb.group({
      voltageType: [battery.voltageType],
      total: [battery.total],
      description: [battery.description],
      spkNumber: [battery.spkNumber]
    });
  }

  ngOnInit(): void {
    this.spkNumber.valueChanges
      .pipe(
        debounceTime(300), // Adjust debounce time as needed (300ms in this example)
        distinctUntilChanged() // Prevent duplicate consecutive values
      )
      .subscribe(value => {
        this.getMotorcycleUnits(value);
      });

    this.onGetDealerByUser();
  }

  getMotorcycleUnits(spkNumber: string): void {
    const queryParams = { spkNumber }; // Create the queryParams object
    this._SPKBatteryRentRest.getMotorcycleUnits(queryParams).subscribe({
      next: response => {
        this.motorcycleUnits = response;
        this.totalAllUnits = response.reduce((accumulator, current) => {
          return accumulator + current.total;
        }, 0);
      },
      error: err => {
        console.error('Error fetching motorcycle units:', err);
        // Handle the error here
      }
    });
  }

  getSPKNumber(batteryCode: string, index: number): void {
    const queryParams = { batteryCode }; // Create the queryParams object

    this._SPKBatteryRentRest.getSPKNumber(queryParams).subscribe({
      next: response => {
        // Set the spkNumber to the corresponding battery at the given index
        this.batteries[index].spkNumber = response.spk.spkNumber;
        this.batteries[index].voltageType = response.voltageType;
        this.batteries[index].total = 1;
      },
      error: err => {
        console.error('Error fetching SPK number:', err);
        // Handle the error here
      }
    });
  }

  onGetDealerByUser() {
    this.loadingUpdateProfileDealer = true;
    const userId: string = this._authentication.user?._id;
    this._SPKBatteryRentRest.getDealerByUser(userId).subscribe(
      data => {
        this.dealer = data;
        this.loadingUpdateProfileDealer = false;

        // Initialize formSubmission after dealer data is fetched
        this.formSubmission = {
          dealerId: this.dealer?._id, // Now dealer has a value
          shippingAddress: this.dealer?.address, // Now shippingAddress is correctly populated
          batteries: this.batteries
        };
      },
      error => {
        this.loadingUpdateProfileDealer = false;
      }
    );
  }

  onImageFinishUploaded(image, inputName: string) {
    this.formSubmission[inputName] = image.storageFileName;
  }

  // Initiate Form
  formSubmission: any = {};

  addBaterai(): void {
    this.batteries.push({
      voltageType: '',
      total: 0,
      description: '',
      photos: []
    });
  }

  removeBaterai(index: number): void {
    this.batteries.splice(index, 1);
  }

  toDate(date) {
    return moment(date).format('DD MMM YYYY');
  }

  toDateInput(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  openNewSubmissionModal() {
    this.isNewSubmissionModalOpen = true;
  }

  closeNewSubmissionModal() {
    this.isNewSubmissionModalOpen = false;
    this.resetInput();
  }

  openBackupSubmissionModal() {
    this.isBackupSubmissionModalOpen = true;
  }

  closeBackupSubmissionModal() {
    this.isBackupSubmissionModalOpen = false;
    this.resetInput();
  }

  resetInput() {
    this.batteries = [
      {
        voltageType: '',
        total: '',
        description: ''
      }
    ];
  }

  openReplacementSubmissionModal(data) {
    this.isReplacementSubmissionModalOpen = true;
    this.replacementSubmissionOpened = data;
    this.type = 'replacement'; // or 'return'
  }

  closeReplacementSubmissionModal() {
    this.isReplacementSubmissionModalOpen = false;
  }

  isMandalaOrPatriot(): boolean {
    if (!this.motorcycleUnits || this.motorcycleUnits.length === 0) {
      return false;
    }

    return this.motorcycleUnits.some(
      unit =>
        unit.unit.toLowerCase().includes('mandalax') ||
        unit.unit.toLowerCase().includes('patriotx') ||
        unit.unit.toLowerCase().includes('mandala x') ||
        unit.unit.toLowerCase().includes('patriot x')
    );
  }

  async onSubmitSubmission(type: string) {
    this.formSubmission.poRentType = type;
    this.formSubmission.type = 'baterai_sewa';

    // Validasi total baterai
    const hasInvalidBattery = this.formSubmission.batteries.some(battery => battery.total > 10);
    if (hasInvalidBattery) {
      return Swal.fire('Oops..', 'Total baterai tidak boleh lebih dari 10!', 'error');
    }

    for (const key in this.formSubmission) {
      if (this.formSubmission[key] === '' || this.formSubmission[key] == null) {
        return Swal.fire('Oops..', 'All fields must be filled out.', 'error');
      }
    }

    // Calculate totals in batteries array
    const currentTotalUnit = this.batteries.reduce((accumulator, current) => {
      return accumulator + current.total;
    }, 0);

    // Total validation
    // if (type === 'new' || type === 'backup') {
    //   // If the type is not 'new', it must be 'backup', which allows only 20% of the total.
    //   const maxTotalUnit = type === 'new' ? this.totalAllUnits : this.totalAllUnits * 0.2;
    //   const errorMessage =
    //     type === 'new'
    //       ? 'Total pengajuan tidak bisa melebihi total unit!'
    //       : 'Total pengajuan tidak bisa melebihi 20% dari total unit!';

    //   if (currentTotalUnit > maxTotalUnit) {
    //     return Swal.fire('Oops..', errorMessage, 'error');
    //   }
    // }

    // Merge photo into photos array
    const photos = Object.keys(this.formSubmission)
      .filter(key => key.startsWith('photo'))
      .map(key => this.formSubmission[key]);

    this.formSubmission.photos = photos;

    // Remove the individual "photo" keys
    for (const key of Object.keys(this.formSubmission)) {
      if (key.startsWith('photo') && key !== 'photos') {
        delete this.formSubmission[key];
      }
    }

    await this._SPKBatteryRentRest
      .createSPK(this.formSubmission)
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire('Success', 'Pengajuan sukses!', 'success');

          this._spkBatteryRentListComponent.callHookDirectly('init');
          type === 'new' ? this.closeNewSubmissionModal() : this.closeBackupSubmissionModal();
        }
      })
      .catch(error => {
        console.log(error);
        Swal.fire('Oops..', 'Something went wrong!', 'error');
      });
    this.isLoading = false;

    this.resetInput();
  }

  // Define all available voltage types
  allVoltageTypes: string[] = ['72v', '64v'];

  // Get available voltage types for a specific battery
  getAvailableVoltageTypes(index: number): string[] {
    // Get all selected voltage types except the current one
    const selectedTypes = this.batteries
      .filter((_, i) => i !== index)
      .map(b => b.voltageType)
      .filter(type => type !== '');

    // Return only voltage types that aren't already selected
    return this.allVoltageTypes.filter(type => !selectedTypes.includes(type));
  }
}
