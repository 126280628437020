import { Component, ElementRef } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { BatteryRentRestService } from '../battery-rent-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';
import { AclService } from '../../../libraries/auth/acl.service';
import Swal from 'sweetalert2';
import { APP_CONST } from '../../../app.const';

@Component({
  selector: 'app-battery-rent-list',
  templateUrl: 'battery-rent-list.component.html',
  styleUrls: ['./battery-rent-list.component.scss']
})
export class BatteryRentListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.batteryRent.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.batteryRent.entity.policeNumber',
      field: 'motorcycleNumber',
      sort: true
    },
    {
      i18nLabel: 'ui.batteryRent.entity.chassisNumber',
      field: 'chassisNumber',
      sort: true
    },
    {
      i18nLabel: 'ui.batteryRent.entity.customerName',
      field: 'customerName',
      sort: true
    },
    {
      i18nLabel: 'ui.batteryRent.entity.ktpNumber',
      field: 'nik',
      sort: true
    },
    {
      i18nLabel: 'ui.batteryRent.entity.phoneNumber',
      field: 'customerPhone',
      sort: true
    },
    {
      i18nLabel: 'ui.batteryRent.entity.batteryCode',
      name: 'batteryCode',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.batteryRent.entity.voltageType',
      field: 'voltageType',
      sort: true
    },
    {
      i18nLabel: 'ui.batteryRent.entity.note',
      name: 'alasan',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.batteryRent.entity.duration',
      name: 'duration',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.batteryRent.entity.startRentDate',
      name: 'firstPurchaseDate',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.batteryRent.entity.expiryRentDate',
      name: 'currentExpiredDate',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.batteryRent.entity.membershipPurchaseHistory',
      name: 'membershipPurchaseHistory',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.batteryRent.entity.status',
      name: 'status',
      field: '',
      sort: true
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(this.tableColumns);
  isLoadingExportExcel: boolean = false;
  isNewSubmissionModalOpen: boolean = false;
  newSubmissionOpened: any = {};
  isReplacementSubmissionModalOpen: boolean = false;
  replacementSubmissionOpened: any = {};
  uploadEndpoint = APP_CONST.API_FILE;
  newBatteryCode: string = '';
  alasan: string = '';
  isMembershipBatteryModalOpen: boolean = false;
  membershipBatteryData: any = {};
  isLoading = false;

  statuses = [
    {
      text: 'Semua Status',
      value: ''
    },
    {
      text: 'Waiting',
      value: 'waiting'
    },
    {
      text: 'Approved',
      value: 'approved'
    },
    {
      text: 'Rejected',
      value: 'rejected'
    }
  ];

  types = [
    {
      text: 'Semua Tipe Pengajuan',
      value: ''
    },
    {
      text: 'Waiting',
      value: 'waiting'
    },
    {
      text: 'Approved',
      value: 'approved'
    },
    {
      text: 'Rejected',
      value: 'rejected'
    }
  ];

  constructor(
    public _authentication: AuthenticationService,
    private _BatteryRentRest: BatteryRentRestService,
    private _export: ExportDataTableService,
    private _AclService: AclService,
    private el: ElementRef
  ) {
    super();
    this.componentId = 'BatteryRentListComponent';
    this.headerTitle = 'ui.batteryRent.list.title';
    this.qParams.status = '';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign({}, event.data.qParams, qOption, this.qParams);
        return this._BatteryRentRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY HH:mm');
              d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY HH:mm');
              d.firstPurchaseDate = d.firstPurchaseDate ? moment(d?.firstPurchaseDate).format('DD MMM YYYY') : '-';
              d.currentExpiredDate = d.currentExpiredDate ? moment(d?.currentExpiredDate).format('DD MMM YYYY') : '-';
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService?.callMultipleHooks(['BatteryRentListComponent:workflowLoadData'])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  openNewSubmissionModal(data) {
    this.isNewSubmissionModalOpen = true;
    this.newSubmissionOpened = data;
  }

  closeNewSubmissionModal() {
    this.isNewSubmissionModalOpen = false;
  }

  openReplacementSubmissionModal(data) {
    this.isReplacementSubmissionModalOpen = true;
    this.replacementSubmissionOpened = data?.data;
  }

  closeReplacementSubmissionModal() {
    this.isReplacementSubmissionModalOpen = false;
    this.membershipBatteryData = [];
  }

  openMembershipBatteryModal(data) {
    this.isMembershipBatteryModalOpen = true;
    this.isLoading = true;

    this._BatteryRentRest.getMembershipHistory(data?.data?.batteryCode).subscribe(
      membershipHistory => {
        // Format the date fields
        const formattedData = membershipHistory.data.map(item => ({
          ...item,
          expired_date: moment(item.expired_date).format('DD MMM YYYY'),
          grace_period_date: moment(item.grace_period_date).format('DD MMM YYYY'),
          purchase_date: moment(item.purchase_date).format('DD MMM YYYY')
        }));

        this.membershipBatteryData = formattedData;
        this.isLoading = false;
      },
      error => {
        console.error('Error fetching membership history:', error);
        this.isLoading = false;
      }
    );
  }

  closeMembershipBatteryModal() {
    this.isMembershipBatteryModalOpen = false;
  }

  onImageFinishUploaded(image, inputName: string) {
    // this.form.patchValue({
    //   approvalDokumen: {
    //     [inputName]: image?.fileUrl,
    //   }
    // });
    console.log('image', image);
  }

  canAccess(pageAction: string) {
    const userAccess: string[] = this._AclService?.exclusivePermissions;
    return userAccess.find(action => action === pageAction);
  }

  getStatusText(status: string): string {
    const statusMap = {
      'Membership Aktif': 'success',
      'Masa Tenggang': 'pending',
      'Membership Tidak Aktif': 'failed'
    };
    return statusMap[status] || '';
  }

  stopRent(id: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to stop renting this battery? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, stop it!',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.isConfirmed) {
        this._BatteryRentRest.stopRent({ _id: id }).subscribe({
          next: response => {
            Swal.fire('Success', 'Unpair battery success!', 'success');
            this.callHookDirectly('init');
          },
          error: err => {
            console.error('Error submitting form:', err);
            Swal.fire('Error', 'Failed to unpair battery. Please try again.', 'error');
          }
        });
      }
    });
  }

  replaceBattery(data): void {
    const payload = {
      _id: data._id,
      newBatteryCode: this.newBatteryCode,
      alasan: this.alasan
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to replace this battery? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, replace it!',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.isConfirmed) {
        this._BatteryRentRest.replace(payload).subscribe({
          next: response => {
            Swal.fire('Success', 'Replace battery success!', 'success');
            this.callHookDirectly('init');
            this.isReplacementSubmissionModalOpen = false;
          },
          error: err => {
            console.error('Error submitting form:', err);
            Swal.fire('Error', 'Failed to replace battery. Please try again.', 'error');
          }
        });
      }
    });
  }

  getStatusColor(status: string) {
    if (status == 'renting') return 'primary';
    if (status == 'replacement') return 'success';
    if (status == 'waiting for pairing') return 'warning';
    if (status == 'stop rent') return 'danger';
    if (status == 'GRACE PERIOD') return 'danger';
    if (status == 'INACTIVE') return 'danger';
    if (status == 'EXPIRED') return 'danger';
  }

  formatDate(date: string) {
    return moment(date).format('DD MMMM YYYY HH:mm');
  }
}
