import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { FormgenModule } from '../../libraries/formgen/formgen.module';
import { LibrariesModule } from '../../libraries/libraries.module';
import { SharedModule } from '../../libraries/shared.module';
import { BatteryRentListComponent } from './list/battery-rent-list.component';
import { BatteryRentRestService } from './battery-rent-rest.service';
import { BatteryRentRoutingModule } from './battery-rent-routing.module';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ChipComponent } from './components/chip/chip.component';
import { AddBatteryRentModalComponent } from './add-battery-rent-modal/add-battery-rent.compnent';

export const PROVIDERS = [BatteryRentRestService];

@NgModule({
  imports: [
    BatteryRentRoutingModule,
    BsDropdownModule,
    CommonModule,
    FlexLayoutModule,
    FormgenModule,
    FormsModule,
    ListboxModule,
    LibrariesModule,
    PaginationModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TableModule,
    TranslateModule,
    DialogModule,
    ButtonModule
  ],
  declarations: [BatteryRentListComponent, ChipComponent, AddBatteryRentModalComponent]
})
export class BatteryRentModule {}
