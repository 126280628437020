import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SPKRestService } from '../spk-cash-rest.service';
import Swal from 'sweetalert2';
import { SPKListComponent } from '../list/spk-list.component';
import { CommonService } from '../../../libraries/libraries.module';

@Component({
  selector: 'app-shipping-cost',
  templateUrl: 'shipping-cost.component.html',
  styleUrls: ['../spk-cash-component.scss']
})
export class ShippingCostComponent {
  @Input() spk: any;

  constructor(private _SPKRest: SPKRestService, public _spkListComponent: SPKListComponent) {
    this.initFormShippingCost();
  }

  isLoading: boolean = false;
  formShippingCost: FormGroup;
  isModalOpen: boolean = false;
  dataInputType: string;
  formModalBatchUpdatePrice: FormGroup;
  isFormModalCreateSubmitting: boolean = false;

  initFormShippingCost() {
    this.formShippingCost = new FormGroup({
      shippingCost: new FormControl('', [Validators.required]),
      chassisNumbers: new FormArray([])
      // batteryCodes: new FormArray([]),
    });

    // Populate with default empty input
    this.addChassisNumber();
    // this.addBatteryCode();

    this.formModalBatchUpdatePrice = new FormGroup({
      file: new FormControl('', [Validators.required]),
      fileName: new FormControl(''),
      shippingCost: new FormControl('', [Validators.required])
    });
  }

  get chassisNumbers() {
    return this.formShippingCost.get('chassisNumbers') as FormArray;
  }

  // get batteryCodes() {
  //   return this.formShippingCost.get('batteryCodes') as FormArray;
  // }

  addChassisNumber() {
    this.chassisNumbers.push(new FormControl(''));
  }

  // addBateryCode() {
  //   this.batteryCodes.push(new FormControl('', Validators.required));
  // }

  removeChassisNumber(index: number) {
    this.chassisNumbers.removeAt(index);
  }

  // removeBatteryCode(index: number) {
  //   this.batteryCodes.removeAt(index);
  // }

  openModal() {
    this.dataInputType = 'manual';

    this.formShippingCost.patchValue({
      shippingCost: this.spk.shippingCost
    });

    this.formModalBatchUpdatePrice.patchValue({
      shippingCost: this.spk.shippingCost
    });

    // CHASSIS NUMBERS
    this.chassisNumbers.clear();
    let hasChassis = false;

    if (this.spk.spkShippingInformation?.length) {
      this.spk.spkShippingInformation.forEach((shippingInfo: any) => {
        // Filter shippingInfo dengan type 'chassis'
        if (shippingInfo.type === 'chassis' && shippingInfo.unitCodes?.length) {
          hasChassis = true;
          shippingInfo.unitCodes.forEach((code: string) => {
            this.chassisNumbers.push(new FormControl(code));
          });
        }
      });
    }

    // Fallback kalau tidak ada data battery
    if (!hasChassis) {
      this.addChassisNumber();
    }

    // BATTERY CODES
    // this.batteryCodes.clear();
    // let hasBattery = false;

    // if (this.spk.spkShippingInformation?.length) {
    //   this.spk.spkShippingInformation.forEach((shippingInfo: any) => {
    //     // Filter shippingInfo dengan type 'battery'
    //     if (shippingInfo.type === 'battery' && shippingInfo.unitCodes?.length) {
    //       hasBattery = true;
    //       shippingInfo.unitCodes.forEach((code: string) => {
    //         this.batteryCodes.push(new FormControl(code, Validators.required));
    //       });
    //     }
    //   });
    // }

    // // Fallback kalau tidak ada data battery
    // if (!hasBattery) {
    //   this.addBateryCode();
    // }

    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
    this.dataInputType = 'manual';
    this.formShippingCost.reset();
    this.formModalBatchUpdatePrice.reset();
  }

  async uploadBulkPrice() {
    if (!this.formModalBatchUpdatePrice.valid) {
      CommonService.markAsDirty(this.formModalBatchUpdatePrice);
    } else {
      const formData = new FormData();
      formData.append('excel_file', this.formModalBatchUpdatePrice.value.file);
      formData.append('shippingCost', this.formModalBatchUpdatePrice.value.shippingCost);
      console.log('formData', this.formModalBatchUpdatePrice);
      await this._SPKRest
        .bulkUploadUnitCodes(this.spk._id, formData)
        .toPromise()
        .then(response => {
          if (response) {
            Swal.fire('Update Shipping Information successfully!', '', 'success');

            this._spkListComponent.callHookDirectly('init');
            this.closeModal();
          }
        })
        .catch(error => {
          console.log(error);
          Swal.fire('Oops..', 'Something went wrong!', 'error');
        });
      this.isLoading = false;
    }
  }

  async updateShippingCost() {
    if (!this.formShippingCost.valid) {
      CommonService.markAsDirty(this.formShippingCost);
    } else {
      const formShippingCostValue = this.formShippingCost.value;

      // Remove empty elements from batteryCodes array
      if (formShippingCostValue.batteryCodes && Array.isArray(formShippingCostValue.batteryCodes)) {
        formShippingCostValue.batteryCodes = formShippingCostValue.batteryCodes.filter(element => element !== '');
      }
      
      // Remove empty elements from chassisNumbers array
      if (formShippingCostValue.chassisNumbers && Array.isArray(formShippingCostValue.chassisNumbers)) {
        formShippingCostValue.chassisNumbers = formShippingCostValue.chassisNumbers.filter(element => element !== '');
      }
      
      // Construct the payload
      const payload = {
        ...formShippingCostValue,
        spkId: this.spk._id
      };
      this.isLoading = true;

      await this._SPKRest
        .updateShippingCost(payload)
        .toPromise()
        .then(response => {
          if (response) {
            Swal.fire('Update Shipping Information successfully!', '', 'success');

            this._spkListComponent.callHookDirectly('init');
            this.closeModal();
          }
        })
        .catch(error => {
          console.log(error);
          Swal.fire('Oops..', 'Something went wrong!', 'error');
        });
      this.isLoading = false;
    }
  }

  onFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      // const reader = new FileReader();
      const file = target.files[0];
      // const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);

      // reader.onload = () => {
      // this.invoiceImageBase64 = reader.result as string;
      // };

      // reader.readAsDataURL(file);

      this.formModalBatchUpdatePrice.patchValue({
        file: file,
        fileName: file.name
      });

      // this.selectedFileName = file.name;
      // this.fileSize = fileSizeMB + 'MB';
    }
  }
}
