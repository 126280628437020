import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { BasicLayoutComponent } from './layouts/basic/basic-layout.component';

export const routes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./main/login/login.module').then(m => m.LoginModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./main/dashboard/dashboard.module').then(
            m => m.DashboardModule
          ),
        data: {
          breadcrumb: 'Dashboard'
        }
      },
      {
        path: 'roles',
        loadChildren: () =>
          import('./main/role/role.module').then(m => m.RoleModule),
        data: {
          breadcrumb: 'Roles'
        }
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./main/user/user.module').then(m => m.UserModule),
        data: {
          breadcrumb: 'Users'
        }
      },
      {
        path: 'spk',
        loadChildren: () =>
          import('./main/spk/spk.module').then(m => m.SPKModule),
        data: {
          breadcrumb: 'SPK'
        }
      },
      {
        path: 'spk-cash',
        loadChildren: () =>
          import('./main/spk-cash/spk-cash.module').then(m => m.SPKCashModule),
        data: {
          breadcrumb: 'SPK PO Cash'
        }
      },
      {
        path: 'spk-indent',
        loadChildren: () =>
          import('./main/spk-indent/spk-indent.module').then(
            m => m.SPKIndentModule
          ),
        data: {
          breadcrumb: 'SPK Indent'
        }
      },
      {
        path: 'spk-battery',
        loadChildren: () =>
          import('./main/spk-battery/spk-battery.module').then(
            m => m.SPKBatteryModule
          ),
        data: {
          breadcrumb: 'SPK PO Battery'
        }
      },
      {
        path: 'spk-lite-to-reguler',
        loadChildren: () => import('./main/spk-lite-to-reguler/spk-lite-to-reguler.module').then(m => m.SPKLiteToRegulerModule),
        data: {
          breadcrumb: 'SPK Lite to Reguler'
        }
      },
      {
        path: 'po',
        loadChildren: () => import('./main/po/po.module').then(m => m.PoModule),
        data: {
          breadcrumb: 'List Order'
        }
      },
      {
        path: 'banner',
        loadChildren: () =>
          import('./main/banner/banner.module').then(m => m.BannerModule),
        data: {
          breadcrumb: 'Banner'
        }
      },
      {
        path: 'promo',
        loadChildren: () =>
          import('./main/promo/promo.module').then(m => m.PromoModule),
        data: {
          breadcrumb: 'Promo'
        }
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./main/news/news.module').then(m => m.NewsModule),
        data: {
          breadcrumb: 'News'
        }
      },
      {
        path: 'partnership-business',
        loadChildren: () =>
          import(
            './main/partnership-business/partnership-business.module'
          ).then(m => m.PartnershipBusinessModule),
        data: {
          breadcrumb: 'Business Partner Request'
        }
      },
      {
        path: 'partnership',
        loadChildren: () =>
          import('./main/partnership/partnership.module').then(
            m => m.PartnershipModule
          ),
        data: {
          breadcrumb: 'Dealer Partner Request'
        }
      },
      {
        path: 'affiliator',
        loadChildren: () =>
          import('./main/affiliator/affiliator.module').then(
            m => m.AffiliatorModule
          ),
        data: {
          breadcrumb: 'Affiliator'
        }
      },
      {
        path: 'affiliator-claim',
        loadChildren: () =>
          import('./main/affiliator-claim/affiliator-claim.module').then(
            m => m.AffiliatorClaimModule
          ),
        data: {
          breadcrumb: 'Affiliator Claim'
        }
      },
      {
        path: 'agent-bfi',
        loadChildren: () =>
          import('./main/agent-bfi/agent-bfi.module').then(
            m => m.AgentBfiModule
          ),
        data: {
          breadcrumb: 'Agent BFI'
        }
      },
      {
        path: 'agent-bfi-claim',
        loadChildren: () =>
          import('./main/agent-bfi-claim/agent-bfi-claim.module').then(
            m => m.AgentBfiClaimModule
          ),
        data: {
          breadcrumb: 'Claim Agent BFI'
        }
      },
      {
        path: 'partnership-gentari',
        loadChildren: () =>
          import('./main/partnership-gentari/partnership-gentari.module').then(
            m => m.PartnershipGentariModule
          ),
        data: {
          breadcrumb: 'Partnership Gentari'
        }
      },
      {
        path: 'news-categories',
        loadChildren: () =>
          import('./main/news-categories/news-categories.module').then(
            m => m.NewsCategoriesModule
          ),
        data: {
          breadcrumb: 'News Categories'
        }
      },
      {
        path: 'faq',
        loadChildren: () =>
          import('./main/faq/faq.module').then(m => m.FaqModule),
        data: {
          breadcrumb: 'FAQ'
        }
      },
      {
        path: 'location',
        loadChildren: () =>
          import('./main/location/location.module').then(m => m.LocationModule),
        data: {
          breadcrumb: 'Location'
        }
      },
      {
        path: 'subsidi',
        loadChildren: () =>
          import('./main/subsidi/subsidi.module').then(m => m.SubsidiModule),
        data: {
          breadcrumb: 'Subsidi'
        }
      },
      {
        path: 'sparepart',
        loadChildren: () =>
          import('./main/product/sparepart/product.module').then(
            m => m.ProductModule
          ),
        data: {
          breadcrumb: 'Sparepart'
        }
      },
      {
        path: 'motorcycle',
        loadChildren: () =>
          import('./main/product/motorcycle/product.module').then(
            m => m.ProductModule
          ),
        data: {
          breadcrumb: 'Motorcycle'
        }
      },
      {
        path: 'region-dealer',
        loadChildren: () =>
          import('./main/product/region-dealer/region-dealer.module').then(
            m => m.RegionDealerModule
          ),
        data: {
          breadcrumb: 'Region Dealer'
        }
      },
      {
        path: 'product-type-variant',
        loadChildren: () =>
          import(
            './main/product-type-variant/product-type-variant.module'
          ).then(m => m.ProductTypeVariantModule),
        data: {
          breadcrumb: 'Motorcycle Stock (MD)'
        }
      },
      {
        path: 'battery',
        loadChildren: () =>
          import('./main/battery/battery.module').then(m => m.BatteryModule),
        data: {
          breadcrumb: 'Battery Stock'
        }
      },
      {
        path: 'sgb-mitra',
        loadChildren: () =>
          import('./main/sgb-mitra/sgb-mitra.module').then(
            m => m.SgbMitraModule
          ),
        data: {
          breadcrumb: 'SGB Mitra'
        }
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./main/contact/contact.module').then(m => m.ContactModule),
        data: {
          breadcrumb: 'Contact'
        }
      },
      {
        path: 'customer',
        loadChildren: () =>
          import('./main/customer/customer.module').then(m => m.CustomerModule),
        data: {
          breadcrumb: 'Customer'
        }
      },
      {
        path: 'dealer',
        loadChildren: () =>
          import('./main/dealer/dealer.module').then(m => m.DealerModule),
        data: {
          breadcrumb: 'Dealer'
        }
      },
      {
        path: 'marketing-support',
        loadChildren: () =>
          import('./main/marketing-support/marketing-support.module').then(
            m => m.MarketingSupportModule
          ),
        data: {
          breadcrumb: 'Marketing Support'
        }
      },
      {
        path: 'buyback',
        loadChildren: () =>
          import('./main/buyback/buyback.module').then(m => m.BuybackModule),
        data: {
          breadcrumb: 'Buyback'
        }
      },
      {
        path: 'tradein',
        loadChildren: () =>
          import('./main/tradein/buyback.module').then(m => m.TradeinModule),
        data: {
          breadcrumb: 'Trade In'
        }
      },
      {
        path: 'gojek-swadaya',
        loadChildren: () =>
          import('./main/gojek-swadaya/gojek-swadaya.module').then(m => m.GojekSwadayaModule),
        data: {
          breadcrumb: 'Gojek Swadaya'
        }
      },
      {
        path: 'spk-battery-rent',
        loadChildren: () =>
          import('./main/spk-battery-rent/spk-battery-rent.module').then(m => m.SPKBatteryRentModule),
        data: {
          breadcrumb: 'Baterai Sewa (MD)'
        }
      },
      {
        path: 'battery-rent',
        loadChildren: () =>
          import('./main/battery-rent/battery-rent.module').then(m => m.BatteryRentModule),
        data: {
          breadcrumb: 'Baterai Sewa (Customer)'
        }
      },
      {
        path: 'bluebird-reguler',
        loadChildren: () =>
          import('./main/bluebird-reguler/bluebird-reguler.module').then(m => m.BluebirdRegulerModule),
        data: {
          breadcrumb: 'Bluebird Reguler'
        }
      },
      {
        path: 'bluebird-credit-bri',
        loadChildren: () =>
          import('./main/bluebird-credit-bri/bluebird-credit-bri.module').then(m => m.BluebirdCreditBriModule),
        data: {
          breadcrumb: 'Bluebird Credit BRI'
        }
      },
    ]
  }
  // { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
