import { Component, ViewChild } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { RequestFakturRestService } from '../request-faktur-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';
import Swal from 'sweetalert2';
import { ReturnModalComponent } from '../return/return-modal.component';
import { AclService } from '../../../libraries/auth/acl.service';

@Component({
  selector: 'app-request-faktur-list',
  templateUrl: 'request-faktur-list.component.html',
  styleUrls: ['../request-faktur-module.scss']
})
export class RequestFakturListComponent extends BaseListBComponent<any> {
  @ViewChild('returnModal') returnModal: ReturnModalComponent;

  openReturnModal(requestFaktur: any) {
    if (this.returnModal) {
      this.returnModal.requestFaktur = requestFaktur;
      this.returnModal.openModal();
    }
  }

  canAccess(pageAction: string) {
    const userAccess: string[] = this._AclService?.exclusivePermissions;
    return userAccess.find(action => action === pageAction);
  }

  tableColumns = [
    {
      i18nLabel: 'ui.requestFaktur.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.requestFaktur.entity.requestNumber',
      field: 'requestNumber',
      sort: true
    },
    {
      i18nLabel: 'ui.requestFaktur.entity.status',
      field: '',
      name: 'status',
      sort: true
    },
    {
      i18nLabel: 'ui.requestFaktur.entity.detail',
      field: '',
      name: 'detail',
      sort: false
    },
    {
      i18nLabel: 'ui.requestFaktur.entity.dealer',
      field: '',
      name: 'dealer',
      sort: true
    },
    {
      i18nLabel: 'ui.requestFaktur.entity.shippingProof',
      field: '',
      name: 'noResi',
      sort: true
    },
    {
      i18nLabel: 'ui.requestFaktur.entity.createdAt',
      field: 'createdAt',
      sort: true
    },
    {
      i18nLabel: 'ui.requestFaktur.entity.acceptedAt',
      field: 'acceptanceDate',
      sort: true
    }
  ];

  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(this.tableColumns);
  isLoadingExportExcel: boolean = false;
  isDealerModalOpen: boolean = false;
  dealerOpened: any = {};

  spkStatuses = [
    {
      text: 'Semua Status',
      value: ''
    },
    {
      "text": "Submitted",
      "value": "submitted"
    },
    {
      "text": "Approved By Bisnis",
      "value": "approved-by-bisnis"
    },
    {
      "text": "Rejected By Bisnis",
      "value": "rejected-by-bisnis"
    },
    {
      "text": "Approved By Pabrik",
      "value": "approved-by-pabrik"
    },
    {
      "text": "Rejected By Pabrik",
      "value": "rejected-by-pabrik"
    },
    {
      "text": "Approved By Finance",
      "value": "approved-by-finance"
    },
    {
      "text": "Rejected By Finance",
      "value": "rejected-by-finance"
    },
    {
      "text": "Faktur Dikirim",
      "value": "faktur-dikirim"
    },
    {
      "text": "Closed",
      "value": "closed"
    },
    {
      "text": "Returned",
      "value": "returned"
    },
  ];

  dealerFilter = [
    {
      name: 'Semua Dealer',
      _id: ''
    },
  ];

  constructor(
    public _authentication: AuthenticationService,
    private _RequestFakturRest: RequestFakturRestService,
    private _export: ExportDataTableService,
    private _AclService: AclService,
  ) {
    super();
    this.componentId = 'RequestFakturListComponent';
    this.headerTitle = 'ui.requestFaktur.list.title';

    this.qParams.status = '';
    this.qParams.dealerId = '';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign({}, event.data.qParams, qOption, this.qParams);
        return this._RequestFakturRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.dob = moment(d?.dob).format('DD MMMM YYYY');
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY HH:mm');
              d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY HH:mm');
              d.acceptanceDate = d.acceptanceDate ? moment(d?.acceptanceDate).format('DD MMMM YYYY HH:mm') : "-";
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService?.callMultipleHooks(['RequestFakturListComponent:workflowLoadData'])
    });
  }

  appOnInit() {
    super.appOnInit();

    this.getDealers();

    return this.callHook('init');
  }

  openDealerModal(data) {
    this.isDealerModalOpen = true;
    this.dealerOpened = data;
  }

  closeDealerModal() {
    this.isDealerModalOpen = false;
  }

  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._RequestFakturRest.findAll({}).toPromise();
      const mapOption = {
        fullname: { header: 'Nama' },
        phone: { header: 'Telepon' },
        nik: { header: 'NIK (KTP)' },
        dob: { header: 'Tanggal Lahir' },
        gender: { header: 'Jenis Kelamin' },
        email: { header: 'Email' },
        address: { header: 'Alamat' },
        createdAt: { header: 'Tanggal Dibuat'}
      };
      const payload = result.data.map(item => ({
        ...item,
        dob: moment(item?.dob).format('DD MMMM YYYY'),
        gender: item?.gender == "M" ? "Laki-laki" : "Perempuan",
        createdAt: moment(item?.createdAt).format('DD MMMM YYYY HH:mm')
      }));
      this._export.export({
        extension: 'xlsx',
        fileName: 'RequestFaktur',
        records: payload,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }

  onCloseFaktur(requestFakturId: string) {
    Swal.fire({
      title: 'Apakah anda yakin?',
      text: 'Apakah anda ingin meng-close request faktur ini?',
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.closeFaktur(requestFakturId);
      } else {
        return;
      }
    });
  }
  

  async closeFaktur(id) {
    await this._RequestFakturRest
      .close(id)
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire('Request faktur closed successfully!', '', 'success');
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  getDealers() {
    this._RequestFakturRest.getDealers().subscribe((response: any) => {
      const data = response?.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
      this.dealerFilter = [
        ...this.dealerFilter,
        ...data,
      ];
    })
  }


  getStatusColor(status: string) {    
    if (status.includes('approved')) return 'success';
    if (status.includes('rejected')) return 'danger';
  
    switch (status) {
      case 'submitted':
        return 'primary';
      case 'closed':
      case 'returned':
      case 'faktur-dikirim':
        return 'success';
      default:
        return 'primary';
    }
  }

  removeDash(str: string): string {
    return str.replace(/-/g, ' ');
  }
}
