import { Component } from '@angular/core';

@Component({
  selector: 'app-status-info-modal',
  templateUrl: 'status-info-modal.component.html',
  styleUrls: ['../request-faktur-module.scss']
})
export class StatusInfoModal {

  constructor(){
  }

  isOpen: boolean = false;

  statusList = [
    {
      title: 'Submitted',
      description: 'Request Faktur telah disubmit',
      icon: '',
    },
    {
      title: 'Approved By Bisnis',
      description: 'Request Faktur berhasil disetujui oleh Bisnis',
      icon: '',
    },
    {
      title: 'Rejected By Bisnis',
      description: 'Request Faktur gagal disetujui oleh Bisnis',
      icon: '',
    },
    {
      title: 'Approved By Pabrik',
      description: 'Request Faktur berhasil disetujui oleh Pabrik',
      icon: '',
    },
    {
      title: 'Rejected By Pabrik',
      description: 'Request Faktur gagal disetujui oleh Pabrik',
      icon: '',
    },
    {
      title: 'Approved By Finance',
      description: 'Request Faktur berhasil disetujui oleh Finance',
      icon: '',
    },
    {
      title: 'Rejected By Finance',
      description: 'Request Faktur gagal disetujui oleh Finance',
      icon: '',
    },
    {
      title: 'Faktur Dikirim',
      description: 'Request Faktur telah dikirim',
      icon: '',
    },
    {
      title: 'Closed',
      description: 'Request Faktur sudah ditutup',
      icon: '',
    },
    {
      title: 'Returned',
      description: 'Request Faktur dikembalikan',
      icon: '',
    },
  ]

  openModal() {
    this.isOpen = true;
  }

  closeModal() {
    this.isOpen = false;
  }
}
