import { Component, ElementRef } from '@angular/core';
import 'moment/locale/id';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseListBComponent } from '../../../libraries/base/list/base-list.bcomponent';
import { SPKBatteryRentRestService } from '../spk-battery-rent-rest.service';
import { ExportDataTableService } from '../../../libraries/export/export-data-table.service';
import { AuthenticationService } from '../../../libraries/auth/authentication.service';
import { AclService } from '../../../libraries/auth/acl.service';
import Swal from 'sweetalert2';
import { APP_CONST } from '../../../app.const';

@Component({
  selector: 'app-spk-battery-rent-list',
  templateUrl: 'spk-battery-rent-list.component.html',
  styleUrls: ['./spk-battery-rent-list.component.scss']
})
export class SPKBatteryRentListComponent extends BaseListBComponent<any> {
  tableColumns = [
    {
      i18nLabel: 'ui.spkBatteryRent.entity.no',
      field: 'no'
    },
    {
      i18nLabel: 'ui.spkBatteryRent.entity.spkNumber',
      field: 'spkNumber',
      sort: true
    },
    {
      i18nLabel: 'ui.spkBatteryRent.entity.submissionType',
      field: 'poRentType',
      sort: true
    },
    {
      i18nLabel: 'ui.spkBatteryRent.entity.status',
      name: 'status',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.spkBatteryRent.entity.detail',
      name: 'detail',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.spkBatteryRent.entity.dealer',
      name: 'dealer',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.spkBatteryRent.entity.motorPoNumber',
      name: 'motorPoNumber',
      field: '',
      sort: true
    },
    {
      i18nLabel: 'ui.spkBatteryRent.entity.createdAt',
      field: 'createdAt',
      sort: true
    },
    {
      i18nLabel: 'ui.spkBatteryRent.entity.updatedAt',
      field: 'updatedAt',
      sort: true
    },
    {
      i18nLabel: 'ui.spkBatteryRent.entity.shippingInfo',
      name: 'shippingCost',
      field: '',
      sort: true
    }
  ];
  tableColumnsShow: boolean = false;
  tableColumnsToggle = this._gridTableToggle.mapToggleOptionsFromColumns(this.tableColumns);
  isLoadingExportExcel: boolean = false;
  isDealerModalOpen: boolean = false;
  dealerOpened: any = {};
  photoPreviewModal: boolean = false;
  photoPreviewTitle: string = '';
  isstatusHistoryModalOpen: boolean = false;
  statusHistoryOpened: any = {};
  isDetailModalOpen: boolean = false;
  detailOpened: any = {};
  isPoModalOpen: boolean = false;
  poOpened: any = {};
  isShippingDateModalOpen: boolean = false;
  shippingDateOpened: any = {};
  isShippingInfoModalOpen: boolean = false;
  shippingInfoOpened: any = {};
  uploadEndpoint = APP_CONST.API_FILE;

  selectedSPK: any = {};
  isModalChangeStatusOpen: boolean = false;


  statusList = [
    {
      icon: '../../../../assets/img/icons/new.svg',
      title: 'New',
      description: 'SPK Baru Dibuat'
    },
    {
      icon: '../../../../assets/img/icons/waiting-payment.svg',
      title: 'Waiting Payment',
      description: 'Setelah PI diupload oleh pabrik'
    },
    {
      icon: '../../../../assets/img/icons/po-paid.svg',
      title: 'SPK Paid',
      description: 'Dealer sudah melakukan pembayaran, dan menunggu pabrik mengupdate data no rangka dan no polisi'
    },
    {
      icon: '../../../../assets/img/icons/waiting-sisapira.svg',
      title: 'Waiting Sisapira',
      description: 'Data motor sudah ada, menunggu dealer mendaftarkan ke sisapira dan mengupload bukti verifikasi sisapira'
    },
    {
      icon: '../../../../assets/img/icons/waiting-verification.svg',
      title: 'Waiting Verification',
      description: 'Menunggu admin Volta meng-approve data sisapira dari dealer'
    },
    {
      icon: '../../../../assets/img/icons/approved.svg',
      title: 'Approved',
      description: 'Data sudah di approve admin volta, button claim akan dimunculkan dan dealer bisa melakukan claim subsidi'
    },
    {
      icon: '../../../../assets/img/icons/waiting-claim.svg',
      title: 'Waiting Claim',
      description: 'Dealer sudah mengajukan claim dan tinggal menunggu approval dari admin Volta'
    },
    {
      icon: '../../../../assets/img/icons/claimed.svg',
      title: 'Claimed',
      description: 'Pengajuan claim dari dealer sudah di approve'
    },
    {
      icon: '../../../../assets/img/icons/expired.svg',
      title: 'Expired',
      description: 'SPK telah kedaluwarsa'
    },
    {
      icon: '../../../../assets/img/icons/po-delivered.svg',
      title: 'Delivered',
      description: 'Motor dalam proses pengiriman'
    },
    {
      icon: '../../../../assets/img/icons/rejected.svg',
      title: 'Rejected',
      description: 'SPK telah ditolak'
    },
  ]

  spkStatuses = [
    {
      text: 'Semua Status SPK',
      value: ''
    },
    {
      "text": "New",
      "value": "new"
    },
    {
      "text": "Waiting Payment",
      "value": "waiting payment"
    },
    {
      "text": "SPK Paid",
      "value": "spk paid"
    },
    {
      "text": "Waiting Sisapira",
      "value": "waiting sisapira"
    },
    {
      "text": "Waiting Verification",
      "value": "waiting verification"
    },
    {
      "text": "Approved",
      "value": "approved"
    },
    {
      "text": "Waiting Claim",
      "value": "waiting claim"
    },
    {
      "text": "Claimed",
      "value": "claimed"
    },
    {
      "text": "Expired",
      "value": "expired"
    },
    {
      "text": "Rejected",
      "value": "rejected"
    }
  ];

  types = [
    {
      text: 'Semua Tipe Pengajuan',
      value: ''
    },
    {
      text: 'Baterai Baru',
      value: 'new'
    },
    {
      text: 'Baterai Backup',
      value: 'backup'
    },
    {
      text: 'Penggantian Baterai',
      value: 'replacement'
    },
    {
      text: 'Pengembalian Baterai',
      value: 'return'
    },
  ];

  poRentTypes = {
    'new': 'Baterai Baru',
    'backup': 'Baterai Backup',
    'replacement': 'Penggantian Baterai',
    'return': 'Pengembalian Baterai'
  };

  constructor(
    public _authentication: AuthenticationService,
    private _SPKBatteryRentRest: SPKBatteryRentRestService,
    private _export: ExportDataTableService,
    private _AclService: AclService,
    private el: ElementRef
  ) {
    super();
    this.componentId = 'SPKBatteryRentListComponent';
    this.headerTitle = 'ui.spkBatteryRent.list.title';
    this.qParams.status = '';
    this.qParams.batteryRentType = '';
  }

  appDefineFixedHooks() {
    super.appDefineFixedHooks();

    this.registerHook({
      hookName: 'loadData',
      handle: event => {
        const qOption = event.data.qOption;
        qOption['limit'] = qOption['take'];
        qOption['type'] = 'baterai_sewa';
        delete qOption['take'];
        delete qOption['skip'];
        const qParams = Object.assign({}, event.data.qParams, qOption, this.qParams);
        return this._SPKBatteryRentRest.findAll(qParams).pipe(
          switchMap(doc => {
            const dataFormatted = doc?.data?.map(d => {
              d.createdAt = moment(d?.createdAt).format('DD MMMM YYYY HH:mm');
              d.updatedAt = moment(d?.updatedAt).format('DD MMMM YYYY HH:mm');
              return d;
            });
            doc.data = dataFormatted;
            return of(doc);
          })
        );
      },
      wrapRetryableTask: true,
      wrapErrorMessage: true
    });

    this.registerHook({
      hookName: 'init',
      handle: () => this.page.compHookService?.callMultipleHooks(['SPKBatteryRentListComponent:workflowLoadData'])
    });
  }

  appOnInit() {
    super.appOnInit();

    return this.callHook('init');
  }

  openDealerModal(data) {
    this.isDealerModalOpen = true;
    this.dealerOpened = data;
  }

  closeDealerModal() {
    this.isDealerModalOpen = false;
  }

  openDetailModal(data) {
    this.isDetailModalOpen = true;
    this.detailOpened = data;
  }

  closeDetailModal() {
    this.isDetailModalOpen = false;
  }
  
  openPoModal(data) {
    this.isPoModalOpen = true;
    this.poOpened = data;
  }

  closePoModal() {
    this.isPoModalOpen = false;
  }

  openShippingInfoModal(data) {
    this.isShippingInfoModalOpen = true;
    this.shippingInfoOpened = data;
  }

  closeShippingInfoModal() {
    this.isShippingInfoModalOpen = false;
  }

  onImageFinishUploaded(image, inputName: string) {
    // this.form.patchValue({
    //   approvalDokumen: {
    //     [inputName]: image?.fileUrl,
    //   }
    // });
    console.log("image", image);
  }

  
  onChangeStatusSPK(data) {
    this.selectedSPK = data;
    this.isModalChangeStatusOpen = true;
  }

  closeChangeStatusModal() {
    this.isModalChangeStatusOpen = false;
    this.selectedSPK = {};
  }

  canAccess(pageAction: string) {
    const userAccess: string[] = this._AclService?.exclusivePermissions;
    return userAccess.find(action => action === pageAction);
  }

  showstatusHistoryModal(data: any) {
    this.isstatusHistoryModalOpen = true;
    this.statusHistoryOpened = data;
  }

  closestatusHistoryModal() {
    this.isstatusHistoryModalOpen = false;
  }

  onDeleteSPK(id) {
    Swal.fire({
      title: this.comp._translate.instant('confirm.any.delete.label'),
      text: this.comp._translate.instant('confirm.any.delete.description'),
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.deleteSPK(id);
      } else {
        return;
      }
    });
  }

  onRejectSPK(id) {
    Swal.fire({
      title: 'Reject SPK ini?',
      text: '',
      icon: 'question',
      showCancelButton: true
    }).then(result => {
      if (result.value) {
        this.rejectSPK(id);
      } else {
        return;
      }
    });
  }

  
  async deleteSPK(id) {
    await this._SPKBatteryRentRest
      .delete(id)
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire(
            'Delete SPK successfully!',
            '',
            'success'
          )
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }

  async rejectSPK(id) {
    const payload = { spkId: id };
    await this._SPKBatteryRentRest
      .rejectSPK(payload)
      .toPromise()
      .then(response => {
        if (response) {
          Swal.fire(
            'SPK rejected successfully!',
            '',
            'success'
          )
          this.callHookDirectly('init');
        }
      })
      .catch(error => {
        this.comp._globalSystemMessage.log({
          message: 'Error',
          type: 'error',
          scroll: true
        });
      });
  }


  statusIconPath(status: string) {
    const selected = this.statusList.find(item => item.title.toLowerCase() === status)

    return selected?.icon;
  }

  formatToDate(date: string | undefined): string {
    return date ? moment(date, "DD MMM YYYY").format("YYYY-MM-DD") : '';
  }

  async renderPresignedUrlImage(payload: object, elementId: string) {
    await this._SPKBatteryRentRest.getPresignedUrl(payload).subscribe(
      data => {
        const photoPreview = this.el.nativeElement.querySelector(elementId);

        if (photoPreview) {
          photoPreview.src = data.url;
          photoPreview.href = data.url;
        }
      },
      error => {
        const photoPreview = this.el.nativeElement.querySelector(elementId);
        if (photoPreview) {
          photoPreview.src = '';
        }
      }
    );
  }

  closePhotoPreviewModal() {
    this.photoPreviewModal = false;
  }

  async openPhotoPreview(file, title) {
    const payload = { storageFileName: file?.storageFileName };
    await this.renderPresignedUrlImage(payload, '#js-photoPreview');
    this.photoPreviewModal = true;
    this.photoPreviewTitle = title;
  }

  // TODO: Adjust
  async onDownloadExcel() {
    this.isLoadingExportExcel = true;
    try {
      const result = await this._SPKBatteryRentRest.findAll({}).toPromise();
      const mapOption = {
        memberId: { header: 'Member ID' },
        name: { header: 'Nama' },
        phone: { header: 'No HP' },
        email: { header: 'Email' },
        ktpNumber: { header: 'No KTP' },
        address: { header: 'Alamat Tinggal' },
        province: { header: 'Provinsi' },
        city: { header: 'Kota' },
        dealer: { header: 'Dealer' },
        bankName: { header: 'Nama Bank' },
        bankAccount: { header: 'No Rekening' },
        status: { header: 'Status' },
        createdAt: { header: 'Tanggal Submit' }
      };
      const payload = result.data.map(item => ({
        ...item,
        province: item?.province?.provName,
        city: item.city?.cityName,
        dealer: item.dealer?.name,
        createdAt: moment(item?.createdAt).format('DD MMMM YYYY HH:mm')
      }));
      this._export.export({
        extension: 'xlsx',
        fileName: 'Affiliator',
        records: payload,
        mapOptions: mapOption,
        templateData: null,
        pdfOptions: null
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingExportExcel = false;
    }
  }

  getStatusColor(status: string) {
    if (status == 'waiting') return 'warning';
    if (status == 'approved') return 'success';
    if (status == 'rejected') return 'danger';
  }

  formatDate(date: string) {
    return moment(date).format('DD MMMM YYYY');
  }
}
