import { Injectable } from '@angular/core';
import { APP_CONST } from '../../app.const';
import { RequestService } from '../../libraries/http/request.service';

@Injectable()
export class RequestFakturRestService {
  baseURL = `${APP_CONST.API_MASTER}/admin/request-faktur`;
  request = this._request.new(this.baseURL);

  constructor(private _request: RequestService) {}

  findAll(queryParams: any = {}) {
    return this.request.get<any>(`/`, { params: queryParams });
  }

  create(payload) {
    return this.request.post('/', payload);
  }

  approval(id, payload) {
    return this.request.patch(`/approval/${id}`, payload);
  }

  shipping(id, payload) {
    return this.request.patch(`/shipping/${id}`, payload);
  }

  getChassisBySpkNumber(payload) {
    return this.request.post(`/chassis`, payload);
  }

  close(id) {
    return this.request.patch(`/close/${id}`);
  }

  return(id, payload) {
    return this.request.patch(`/return/${id}`, payload);
  }

  getPresignedUrl(payload) {
    const req = this._request.new(`${APP_CONST.API_MASTER}`);
    return req.post<any>('/admin/spk/pre-signed-url', payload)
  }

  getDealers() {
    const req = this._request.new(`${APP_CONST.API_MASTER}`);
    return req.get('/admin/spk/get-dealers');
  }
}
