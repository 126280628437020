import { Component, ElementRef, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SPKBatteryRentRestService } from '../spk-battery-rent-rest.service';
import Swal from 'sweetalert2';
import { SPKBatteryRentListComponent } from '../list/spk-battery-rent-list.component';
import { CommonService } from '../../../libraries/libraries.module';
import { AclService } from '../../../libraries/auth/acl.service';

@Component({
  selector: 'app-delivery-modal',
  templateUrl: 'delivery-modal.component.html',
  styleUrls: ['../list/spk-battery-rent-list.component.scss']
})
export class DeliveryModalComponent {
  @Input() spk: any;

  constructor(
    private _SPKBatteryRentRest: SPKBatteryRentRestService,
    public _spkBatteryRentListComponent: SPKBatteryRentListComponent,
    private _AclService: AclService,
    private el: ElementRef
  ) {
    this.initFormDeliveryConfirmation();
  }

  isLoading: boolean = false;
  formCreateDeliveryConfirmation: FormGroup;
  previewBase64Image: any;
  isPdfSelected: boolean = false;
  isDeliveryConfirmationModalOpen: boolean = false;
  loadingCreateDeliveryConfirmation: boolean = false;
  selectedFileName: string;
  fileSize: any;
  isEditDeliveryConfirmation: boolean = false;
  deliveryConfirmationOpened: any = {};
  selectedSPK: any = {};

  initFormDeliveryConfirmation() {
    this.formCreateDeliveryConfirmation = new FormGroup({
      description: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
    })
  }
  
  async openDeliveryConfirmationModal(data, isEditDeliveryConfirmation = false) {
    if (['expired', 'rejected'].includes(data.status) && isEditDeliveryConfirmation) return;

    const deliveryConfirmation = data.spkBatteryRent[0].delivery; 
    const isPdf = deliveryConfirmation?.fileId?.mimeType === 'application/pdf';
    this.isPdfSelected = isPdf;

    if (!isEditDeliveryConfirmation && deliveryConfirmation) {
      const payload = { storageFileName: deliveryConfirmation?.fileId?.storageFileName };
      if (isPdf) {
        await this.renderPresignedUrlImage(payload, '#js-deliverypdfpreview');
      } else {
        await this.renderPresignedUrlImage(payload, '#js-deliverypreview');
      }
    } else if (isEditDeliveryConfirmation && deliveryConfirmation) {
      if (isPdf) {
        this.previewBase64Image = 'pdf';
      } else {
        const payload = { storageFileName: deliveryConfirmation?.fileId?.storageFileName };
        this.previewBase64Image = deliveryConfirmation?.fileId?.storageFileName;
        await this.renderPresignedUrlImage(payload, '#js-editDeliveryPreview');
      }

      this.formCreateDeliveryConfirmation.patchValue({ 
        file: deliveryConfirmation?.fileId?.storageFileName, 
        description: deliveryConfirmation.description 
      });
    }

    this.selectedSPK = data;
    this.isEditDeliveryConfirmation = isEditDeliveryConfirmation;
    this.isDeliveryConfirmationModalOpen = true;
    this.deliveryConfirmationOpened = deliveryConfirmation;
  }

  async renderPresignedUrlImage(payload: object, elementId: string) {
    await this._SPKBatteryRentRest.getPresignedUrl(payload).subscribe(data => {
      const deliveryPreview = this.el.nativeElement.querySelector(elementId);

      if (deliveryPreview) {
        deliveryPreview.src = data.url;
        deliveryPreview.href = data.url;
      }
    }, error => {
      const deliveryPreview = this.el.nativeElement.querySelector(elementId);
      if (deliveryPreview) {
        deliveryPreview.src = '';
      }
    })
  }

  closeDeliveryConfirmationModal() {
    this.isDeliveryConfirmationModalOpen = false;
    this.formCreateDeliveryConfirmation.reset();
    this.resetFileImg();
  }

  canAccess(pageAction: string) {
    const userAccess: string[] = this._AclService?.exclusivePermissions;
    return userAccess.find(action => action === pageAction);
  }

  addFile(event) {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      const file = event.target.files[0];
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
      const isPdf = event.target.files[0]?.type === 'application/pdf';

      if (!isPdf) {
        reader.onload = () => {
          this.previewBase64Image = reader.result as string
        };
        reader.readAsDataURL(file);
      } else {
        this.previewBase64Image = 'pdf';
      }

      this.formCreateDeliveryConfirmation.patchValue({ file: file });

      this.selectedFileName = file.name;
      this.fileSize = fileSizeMB + 'MB';
    }
  }

  clearFile(event?: Event) {
    event?.preventDefault();
    this.previewBase64Image = null;
    this.selectedFileName = '';
    this.fileSize = null;
    this.formCreateDeliveryConfirmation.patchValue({ file: null });
  }

  resetFileImg() {
    this.previewBase64Image = null;
    this.selectedFileName = '';
    this.fileSize = null;
  }

  async createDeliveryConfirmation() {
    if (!this.formCreateDeliveryConfirmation.valid) {
      CommonService.markAsDirty(this.formCreateDeliveryConfirmation);
    } else {
      const values = this.formCreateDeliveryConfirmation.value;
      const payload = new FormData();

      payload.append('spkId', this.spk._id);
      payload.append('spkBatteryRentId', this.spk.spkBatteryRentId[0]);
      payload.append('description', values.description);
      payload.append('file', values.file);

      this.loadingCreateDeliveryConfirmation = true;
      console.log("payload", payload);
      await this._SPKBatteryRentRest.confirmDelivery(payload)
        .toPromise()
        .then(response => {
          if (response) {
            Swal.fire(
              'Delivery Confirmation Created Successfully!',
              '',
              'success'
            )

            this._spkBatteryRentListComponent.onGridChange()
            this.closeDeliveryConfirmationModal()
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.loadingCreateDeliveryConfirmation = false;
      this.clearFile();
    }
  }
}