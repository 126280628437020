import { Component, ElementRef, Input } from '@angular/core';
import 'moment/locale/id';
import { RequestFakturRestService } from '../request-faktur-rest.service';
import Swal from 'sweetalert2';
import { RequestFakturListComponent } from '../list/request-faktur-list.component';

@Component({
  selector: 'app-return-modal',
  templateUrl: 'return-modal.component.html',
  styleUrls: ['../request-faktur-module.scss']
})
export class ReturnModalComponent {
  @Input() requestFaktur: any = {};
  isModalOpen: boolean = false;
  photoPreviewModal: boolean = false;
  photoPreviewTitle: string = '';

  formReturn = {
    alasan: ''
  };

  constructor(
    private _RequestFakturRest: RequestFakturRestService,

    public _requestFakturListComponent: RequestFakturListComponent,

    private el: ElementRef
  ) {}

  resetFormData() {
    this.formReturn = {
      alasan: ''
    };
  }

  openModal() {
    this.isModalOpen = true;
    this.getPresignedUrl();
    this.resetFormData();
  }

  closeModal() {
    this.isModalOpen = false;
    this.resetFormData();
  }

  async getPresignedUrl() {
    let filesToProcess = [];

    // Determine the files based on the type of submission
    if (this.requestFaktur.jenisPengajuan === 'perorangan') {
      filesToProcess = [
        {
          storageFileName: this.requestFaktur.dataPerorangan.fotoKtp,
          targetProperty: 'dataPerorangan.fotoKtpSigned'
        },
        {
          storageFileName: this.requestFaktur.dataPerorangan.fotoKk,
          targetProperty: 'dataPerorangan.fotoKkSigned'
        }
      ];
    } else if (this.requestFaktur.jenisPengajuan === 'perusahaan') {
      filesToProcess = [
        {
          storageFileName: this.requestFaktur.dataPerusahaan.fotoNip,
          targetProperty: 'dataPerusahaan.fotoNipSigned'
        },
        {
          storageFileName: this.requestFaktur.dataPerusahaan.dokumenDomisili,
          targetProperty: 'dataPerusahaan.dokumenDomisiliSigned'
        }
      ];
    }

    // Call the API for each file to get the presigned URL
    filesToProcess.forEach(file => {
      const filePayload = { storageFileName: file.storageFileName };
      this._RequestFakturRest.getPresignedUrl(filePayload).subscribe({
        next: response => {
          // Update the original object properties directly
          const properties = file.targetProperty.split('.');
          if (properties.length === 2) {
            this.requestFaktur[properties[0]][properties[1]] = response.url;
          }
        },
        error: err => {
          console.error('Error getting presigned URL:', err);
        }
      });
    });
  }

  closePhotoPreviewModal() {
    this.photoPreviewModal = false;
  }

  async openPhotoPreview(file, title) {
    this.photoPreviewModal = true;
    this.photoPreviewTitle = title;

    setTimeout(() => {
      const photoPreview = this.el.nativeElement.querySelector('#js-photoPreview');
      if (photoPreview) {
        photoPreview.src = file;
        photoPreview.href = file;
      } else {
        console.error('photoPreview element not found');
      }
    }, 0); // Wait for the DOM update
  }

  // Method to handle the submit action
  onSubmit(form) {
    if (form.valid) {
      // Prepare the data to be submitted
      const dataToSubmit = this.formReturn;

      console.log("dataToSubmit", dataToSubmit);

      // Here you would call your service to submit the data
      this._RequestFakturRest.return(this.requestFaktur._id, dataToSubmit).subscribe({
        next: response => {
          Swal.fire('Success', 'Return request faktur success!', 'success');
          this._requestFakturListComponent.callHookDirectly('init');
          this.closeModal(); // Close modal on success
        },
        error: err => {
          const errorMessage = err.response.data.message;
                    
          Swal.fire('Error', `Failed to return request faktur: ${errorMessage}`, 'error');
        }
      });
    } else {
      Swal.fire('Error', 'Please fill in all required fields.', 'error');
    }
  }
}
