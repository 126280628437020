import { Component, ElementRef, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SPKBatteryRentRestService } from '../spk-battery-rent-rest.service';
import Swal from 'sweetalert2';
import { SPKBatteryRentListComponent } from '../list/spk-battery-rent-list.component';
import { CommonService } from '../../../libraries/libraries.module';
import { AclService } from '../../../libraries/auth/acl.service';

@Component({
  selector: 'app-approval-modal',
  templateUrl: 'approval-modal.component.html',
  styleUrls: ['../list/spk-battery-rent-list.component.scss']
})
export class ApprovalModalComponent {
  @Input() spk: any;

  constructor(
    private _SPKBatteryRentRest: SPKBatteryRentRestService,
    public _spkBatteryRentListComponent: SPKBatteryRentListComponent,
    private _AclService: AclService,
    private el: ElementRef
  ) {
    this.initFormApproval();
  }

  isLoading: boolean = false;
  formCreateApproval: FormGroup;
  previewBase64Image: any;
  isPdfSelected: boolean = false;
  isApprovalModalOpen: boolean = false;
  loadingCreateApproval: boolean = false;
  selectedFileName: string;
  fileSize: any;
  isEditApproval: boolean = false;
  approvalOpened: any = {};
  selectedSPK: any = {};

  initFormApproval() {
    this.formCreateApproval = new FormGroup({
      description: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
    })
  }
  
  async openApprovalModal(data, isEditApproval = false) {
    if (['expired', 'rejected'].includes(data.status) && isEditApproval) return;

    const approval = data.spkBatteryRent[0].approval;
    
    console.log(approval);
    const isPdf = approval?.fileId?.mimeType === 'application/pdf';
    this.isPdfSelected = isPdf;

    if (!isEditApproval && approval) {
      const payload = { storageFileName: approval?.fileId?.storageFileName };
      if (isPdf) {
        await this.renderPresignedUrlImage(payload, '#js-deliverypdfpreview');
      } else {
        await this.renderPresignedUrlImage(payload, '#js-deliverypreview');
      }
    } else if (isEditApproval && approval) {
      if (isPdf) {
        this.previewBase64Image = 'pdf';
      } else {
        const payload = { storageFileName: approval?.fileId?.storageFileName };
        this.previewBase64Image = approval?.fileId?.storageFileName;
        await this.renderPresignedUrlImage(payload, '#js-editDeliveryPreview');
      }

      this.formCreateApproval.patchValue({ 
        file: approval?.fileId?.storageFileName, 
        description: approval.description 
      });
    }

    this.selectedSPK = data;
    this.isEditApproval = isEditApproval;
    this.isApprovalModalOpen = true;
    this.approvalOpened = approval;

    console.log("this.approvalOpened", this.approvalOpened);
  }

  async renderPresignedUrlImage(payload: object, elementId: string) {
    await this._SPKBatteryRentRest.getPresignedUrl(payload).subscribe(data => {
      const deliveryPreview = this.el.nativeElement.querySelector(elementId);

      if (deliveryPreview) {
        deliveryPreview.src = data.url;
        deliveryPreview.href = data.url;
      }
    }, error => {
      const deliveryPreview = this.el.nativeElement.querySelector(elementId);
      if (deliveryPreview) {
        deliveryPreview.src = '';
      }
    })
  }

  closeApprovalModal() {
    this.isApprovalModalOpen = false;
    this.formCreateApproval.reset();
    this.resetFileImg();
  }

  canAccess(pageAction: string) {
    const userAccess: string[] = this._AclService?.exclusivePermissions;
    return userAccess.find(action => action === pageAction);
  }

  addFile(event) {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      const file = event.target.files[0];
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
      const isPdf = event.target.files[0]?.type === 'application/pdf';

      if (!isPdf) {
        reader.onload = () => {
          this.previewBase64Image = reader.result as string
        };
        reader.readAsDataURL(file);
      } else {
        this.previewBase64Image = 'pdf';
      }

      this.formCreateApproval.patchValue({ file: file });

      this.selectedFileName = file.name;
      this.fileSize = fileSizeMB + 'MB';
    }
  }

  clearFile(event?: Event) {
    event?.preventDefault();
    this.previewBase64Image = null;
    this.selectedFileName = '';
    this.fileSize = null;
    this.formCreateApproval.patchValue({ file: null });
  }

  resetFileImg() {
    this.previewBase64Image = null;
    this.selectedFileName = '';
    this.fileSize = null;
  }

  async createApproval() {
    if (!this.formCreateApproval.valid) {
      CommonService.markAsDirty(this.formCreateApproval);
    } else {
      const values = this.formCreateApproval.value;
      const payload = new FormData();


      payload.append('spkId', this.spk._id);
      payload.append('spkBatteryRentId', this.spk.spkBatteryRentId[0]);
      payload.append('description', values.description);
      payload.append('file', values.file);
      payload.append('status', values.status);

      this.loadingCreateApproval = true;
      await this._SPKBatteryRentRest.onApproval(payload)
        .toPromise()
        .then(response => {
          if (response) {
            Swal.fire(
              'Delivery Confirmation Created Successfully!',
              '',
              'success'
            )

            this._spkBatteryRentListComponent.onGridChange()
            this.closeApprovalModal()
          }
        })
        .catch(error => {
          console.log(error)
          Swal.fire(
            'Oops..',
            'Something went wrong!',
            'error'
          )
        });
      this.loadingCreateApproval = false;
      this.clearFile();
    }
  }
}