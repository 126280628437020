import { Component, ElementRef } from '@angular/core';
import 'moment/locale/id';
import { RequestFakturRestService } from '../request-faktur-rest.service';
import Swal from 'sweetalert2';
import { APP_CONST } from '../../../app.const';
import { RequestFakturListComponent } from '../list/request-faktur-list.component';

@Component({
  selector: 'app-add-modal',
  templateUrl: 'add-modal.component.html',
  styleUrls: ['../request-faktur-module.scss']
})
export class AddModalComponent {
  isModalOpen: boolean = false;
  customerData: any = null; // To store fetched customer data
  selectedCustomer: any = null;
  uploadEndpoint = APP_CONST.API_FILE;
  customerType: string = 'perorangan';

  formDataPerorangan = {
    jenisPengajuan: 'perorangan',
    spkNumber: '',
    noRangka: '',
    noDinamo: '',
    noKtp: '',
    noKk: '',
    namaPelanggan: '',
    fotoKtp: '',
    fotoKk: ''
  }

  formDataPerusahaan = {
    jenisPengajuan: 'perusahaan',
    spkNumber: '',
    noRangka: '',
    noDinamo: '',
    nip: '',
    fotoNip: '',
    dokumenDomisili: ''
  };

  onMotorcycleNumberChange(index: number) {
    this.selectedCustomer = this.customerData[index];
  }

  constructor(
    private _RequestFakturRest: RequestFakturRestService,

    public _requestFakturListComponent: RequestFakturListComponent
  ) {}

  resetFormData() {
    this.formDataPerorangan = {
      jenisPengajuan: 'perorangan',
      spkNumber: '',
      noRangka: '',
      noDinamo: '',
      noKtp: '',
      noKk: '',
      namaPelanggan: '',
      fotoKtp: '',
      fotoKk: ''
    };
  
    this.formDataPerusahaan = {
      jenisPengajuan: 'perusahaan',
      spkNumber: '',
      noRangka: '',
      noDinamo: '',
      nip: '',
      fotoNip: '',
      dokumenDomisili: ''
    };
  }

  openModal() {
    this.isModalOpen = true;
    this.customerData = null; // Reset customer data when modal is opened
    this.resetFormData();
  }
  
  closeModal() {
    this.isModalOpen = false;
    this.resetFormData();
  }

  onSpkInputChange() {
    if (this.customerType === 'perorangan') {
      if (this.formDataPerorangan.spkNumber) {
        this.fetchCustomerData(); // Trigger fetch when input reaches 16 characters
      }
    } else {
      if (this.formDataPerusahaan.spkNumber) {
        this.fetchCustomerData(); // Trigger fetch when input reaches 16 characters
      }
    }
  }

  fetchCustomerData() {
    if (this.customerType === 'perorangan') {
      if (!this.formDataPerorangan.spkNumber) {
        Swal.fire('Error', 'Please enter a valid SPK Number.', 'error');
        return;
      }
    } else {
      if (!this.formDataPerusahaan.spkNumber) {
        Swal.fire('Error', 'Please enter a valid SPK Number.', 'error');
        return;
      }
  }

    let payload = {};
    if (this.customerType === 'perorangan') {
      payload = { spkNumber: this.formDataPerorangan.spkNumber };
    } else {
      payload = { spkNumber: this.formDataPerusahaan.spkNumber };
    }

    this._RequestFakturRest.getChassisBySpkNumber(payload).subscribe({
      next: response => {
        this.customerData = response;
        console.log('Customer Data:', this.customerData);
      },
      error: err => {
        const errorMessage = err.response.data.message;
                        
        Swal.fire('Error', errorMessage, 'error');
      }
    });
  }

  onImageFinishUploaded(image: any, inputName: string) {
    if (image?.storageFileName) {
      if (this.customerType === 'perorangan') {
        // Use type assertion to tell TypeScript this is allowed
        (this.formDataPerorangan as any)[inputName] = image.storageFileName;
      } else {
        (this.formDataPerusahaan as any)[inputName] = image.storageFileName;
      }
    } else {
      Swal.fire("Error", "Image upload failed or missing storageFileName.", 'error');
    }
  }

  // Method to handle the submit action
  onSubmit(form) {    
    if (form.valid) {
      // Prepare the data to be submitted
      const dataToSubmit = this.customerType === 'perorangan' ? 
        { ...this.formDataPerorangan } : 
        { ...this.formDataPerusahaan };
        
      console.log('Data to submit:', dataToSubmit);
      
      // Here you would call your service to submit the data
       this._RequestFakturRest.create(dataToSubmit).subscribe({
            next: response => {
              Swal.fire('Success', 'Request faktur success!', 'success');
              this._requestFakturListComponent.callHookDirectly('init');
              this.closeModal(); // Close modal on success
            },
            error: err => {
              const errorMessage = err.response.data.message;
                        
              Swal.fire('Error', `Failed to request faktur: ${errorMessage}`, 'error');
            }
          });
    } else {
      Swal.fire("Error", 'Please fill in all required fields.', 'error');
    }
  }
}
